import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Typography,
  Form,
  Input,
  Button,
  Alert,
  message,
} from "antd";
import google from "assets/icons/googleauth.svg";

import { Link, useNavigate, useLocation } from "react-router-dom";
import { API, Auth } from "aws-amplify";
import logo from "assets/delphy/delphy.svg";

import { emailPattern } from "utility/constant";
import "./Login.less";
import { setToken } from "store/actions/loginAction";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeeRegi, getMember } from "graphql/queries";
import { setUserData } from "store/actions/userData";
import moment from "moment";
import { ListBillingPlans } from "utility/action";
import { loadStripe } from "@stripe/stripe-js";

const Login = (props) => {
  const location = useLocation();

  const urlParams = new URLSearchParams(window.location.search);
  const procedureName = urlParams.get("procedureName");
  const params = new URLSearchParams(location.search);
  const emailToPopulate = params.get("email");
  const invited = params.get("invite");
  const dpcDoctor = params.get("doctorID");
  const tmpPass = params.get("tmpPassword");
  const planName =
    params.get("data") === null ? null : atob(params.get("data"));

  let planLocalStorage = localStorage.getItem("planName");
  if (planLocalStorage === "null") {
    planLocalStorage = null;
  }

  const [loginData, setLoginData] = useState({
    userName: invited || emailToPopulate ? emailToPopulate : "",
    password: tmpPass ? tmpPass : "",
  });
  // if(invited && emailToPopulate){
  //   setLoginData({ username: emailToPopulate })
  // }
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleState = (name, value) => {
    setLoginData({ [name]: value });
  };

  const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK}`);
  const [stripe, setStripe] = useState([]);

  const getOrgCheckoutSession = async (planId, stripePriceId, userid) => {
    let url = `/billing/checkout/org`;
    try {
      const myInit = {
        body: {
          stripePriceId,
          accountId: userid,
          planId,
        },
      };

      let result = await API.post("bazarhealthREST", url, myInit);
      return result;
    } catch (e) {
      console.log("error retrieving data", e);
      return { error: "Failed to fetch session" };
    }
  };

  const createStripeSession = async (planId, stripePriceId, userID) => {
    try {
      let sessionResult = await getOrgCheckoutSession(
        planId,
        stripePriceId,
        userID
      );
      if (sessionResult.type === "checkout" && sessionResult.sessionId) {
        stripe
          .redirectToCheckout({
            sessionId: sessionResult.sessionId,
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        console.log("err");
      }
    } catch (error) {
      console.log("error creating stripe session", error);
    }
  };

  const resolveStripe = async () => {
    let s = await stripePromise;
    setStripe(s);
  };

  useEffect(() => {
    resolveStripe();
  }, []);

  //login code
  const handleLogin = async (e) => {
    setLoading(true);
    setError("");
    let response = await Auth.signIn(e.userName.trim(), e.password)
      .then(async (response) => {
        const variable = response?.attributes?.sub;
        dispatch(setToken(response?.signInUserSession?.accessToken?.jwtToken));
        localStorage.setItem("given_name", response?.attributes?.given_name);
        Auth.currentAuthenticatedUser().catch((err) => console.log(err));

        if (response?.challengeName === "NEW_PASSWORD_REQUIRED") {
          navigate(
            dpcDoctor
              ? `/create-password?doctorID=${dpcDoctor}`
              : `/create-password`,
            {
              state: {
                userAttributes: response?.challengeParam?.userAttributes,
                oldPassword: e.password,
                id: response?.username,
                firstName: response?.attributes?.given_name,
                lastName: response?.attributes?.family_name,
              },
            }
          );
        }
        if (procedureName) {
          localStorage.setItem("procedureName", procedureName);
        }
        if (planName) {
          localStorage.setItem("planName", planName);
        }
        if (dpcDoctor) {
          localStorage.setItem("dpcDoctor", dpcDoctor);
          localStorage.setItem("currentPlatform", "DPC");
        } else {
          localStorage.setItem("currentPlatform", "Advocacy");
        }

        const user = await Auth.currentAuthenticatedUser();
        const cognitoGroup =
          user.signInUserSession.accessToken.payload["cognito:groups"][0];

        const responce = await API.graphql({
          query: getEmployeeRegi,
          variables: {
            id: variable,
            filter: {
              verified: false,
            },
          },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        const userData = responce?.data?.getEmployeeRegi;
        dispatch(setUserData(responce.data.getEmployeeRegi));
        let planCategory =
          responce?.data?.getEmployeeRegi?.companyID?.planCategory;
        let accountStatus = responce?.data?.getEmployeeRegi?.accountStatus;
        setLoading(false);
        if (cognitoGroup === "SuperAdmin") {
          navigate("/members");
        } else if (dpcDoctor || dpcDoctor !== null) {
          navigate(`/dpc-billing/`);
          localStorage.setItem("currentPlatform", "DPC");
        } else if (
          planName ||
          planLocalStorage ||
          (!accountStatus && planCategory)
        ) {
          navigate(
            `/corporate-billing/?selectedPlan=${
              planName
                ? planName
                : planLocalStorage
                ? planLocalStorage
                : planCategory
            }&allowCheckout=yes`
          );
        } else if (cognitoGroup === "Employee") {
          if (userData) {
            if (userData?.emailType === "PUBLIC") {
              if (
                userData?.profile?.profileCompleted === false &&
                userData?.userType !== null
              ) {
                navigate("/user-profile", {
                  state: { userName: e.userName },
                });
              } else if (
                userData?.profile?.profileCompleted === false &&
                userData?.userType === null
              ) {
                navigate("/onboarding", {
                  state: { id: userData?.id },
                });
              } else {
                navigate("/");
              }
            }
          }
          if (userData) {
            if (userData?.emailType === "PRIVATE") {
              if (userData?.userType === "INDIVIDUAL") {
                if (userData?.companyID === null) {
                  navigate("/user-profile");
                }
              } else if (userData?.emailType === "PRIVATE") {
                if (
                  userData?.companyID === null &&
                  userData.userType === null
                ) {
                  navigate("/onboarding", {
                    state: { id: userData?.id },
                  });
                }
              }
            }
          }

          if (userData) {
            if (
              userData?.userType === "INDIVIDUAL" &&
              userData?.profile?.profileCompleted === false
            ) {
              navigate("/user-profile");
            } else if (
              userData?.userType === "INDIVIDUAL" &&
              userData?.profile?.profileCompleted === true &&
              procedureName
            ) {
              navigate(`/user-procedure-detail/${procedureName}`);
            }
          }

          if (userData) {
            if (userData?.companyID) {
              if (userData?.verified === false) {
                navigate("/approval");
              } else {
                if (userData?.profile?.profileCompleted === false) {
                  navigate("/user-profile");
                } else if (
                  userData?.profile?.profileCompleted === true &&
                  procedureName
                ) {
                  navigate(`/user-procedure-detail/${procedureName}`);
                } else {
                  navigate("/");
                }
              }
            } else {
              if (
                userData?.companyID === null &&
                userData.userType === "CORPORATE" &&
                userData?.verified === false
              ) {
                navigate("/approval");
              }
            }
          }
        } else if (cognitoGroup === "Admin") {
          if (userData) {
            if (userData?.companyID?.approved === false) {
              navigate("/approval");
            } else if (
              userData?.profile?.profileCompleted === true &&
              procedureName
            ) {
              navigate(`/user-procedure-detail/${procedureName}`);
            } else {
              navigate("/");
            }
          }
        } else if (cognitoGroup === "Member") {
          setLoading(true);

          const responce = await API.graphql({
            query: getMember,
            variables: {
              id: variable,
            },
            authMode: "AMAZON_COGNITO_USER_POOLS",
          });
          const memberData = responce?.data?.getMember;
          dispatch(setUserData(responce?.data?.getMember));

          setLoading(false);

          if (memberData) {
            if (memberData?.emailType === "PUBLIC") {
              if (memberData?.userType === "INDIVIDUAL") {
                if (
                  memberData?.accountStatus === "ACTIVE" &&
                  memberData?.profileCompleted === true &&
                  procedureName
                ) {
                  navigate(`/user-procedure-detail/${procedureName}`);
                }
                if (
                  memberData?.accountStatus === null ||
                  memberData?.accountStatus === undefined
                ) {
                  const myInit = {
                    body: {
                      userId: memberData?.id,
                    },
                  };
                  const res = await API.post(
                    "bazarhealthREST",
                    `/billing/update/individualPlanQuantity`,
                    myInit
                  );

                  navigate("/user-profile", {
                    state: { userName: e.userName },
                  });
                } else if (memberData?.accountStatus === "ACTIVE") {
                  navigate("/user-profile", {
                    state: { userName: e.userName },
                  });
                }
              }
            }
          }

          if (memberData) {
            if (memberData?.emailType === "PRIVATE") {
              if (memberData?.userType === "INDIVIDUAL") {
                if (
                  memberData?.accountStatus === null ||
                  memberData?.accountStatus === undefined
                ) {
                  const myInit = {
                    body: {
                      userId: memberData?.id,
                    },
                  };
                  const res = await API.post(
                    "bazarhealthREST",
                    `/billing/update/individualPlanQuantity`,
                    myInit
                  );
                  navigate("/user-profile", {
                    state: { userName: e.userName },
                  });
                } else if (memberData?.accountStatus === "ACTIVE") {
                  navigate("/user-profile", {
                    state: { userName: e.userName },
                  });
                }
              }
            }
          }

          if (memberData) {
            if (memberData?.emailType === "PRIVATE") {
              if (
                memberData?.userType === "CORPORATE" ||
                memberData?.userType === "CORPORATE_ADMIN"
              ) {
                if (
                  memberData?.accountStatus === null ||
                  (memberData?.accountStatus === undefined &&
                    userData?.verified === false)
                ) {
                  navigate("/approval");
                } else {
                  navigate("/user-profile");
                }
              }
            }
          }

          if (memberData) {
            if (memberData?.profile?.profileCompleted === true) {
              navigate("/");
            }
          }
        } else {
          navigate("/corporate-billing", {
            state: {
              id: userData?.id,
            },
          });
        }
      })
      .catch((error) => {
        if (error?.message === "User is not confirmed.") {
          navigate(`/confirm-signup`, {
            state: { feedback: "", userName: e.userName },
          });
        }
        setError(error.message);
        setLoading(false);
        setLoginData({ ...loginData, userName: "", password: "" });
      });

    if (response?.challengeName === "NEW_PASSWORD_REQUIRED") {
      return response;
    }
    return response;
  };

  const signInWithGoogle = async () => {
    try {
      await Auth.federatedSignIn({ provider: "Google" });
    } catch (error) {
      console.log("Error signing in with Google:", error);
    }
  };

  return (
    <>
      <Row className="login-wrapper">
        <Col span={12} lg={12} md={0} sm={0} xs={0}>
          <div className="login-page-image"></div>
        </Col>
        <Col
          className="form-footer-container"
          span={12}
          lg={12}
          md={0}
          sm={24}
          xs={24}
        >
          <Row justify="center" align="middle" className="w-100">
            <Col span={12} md={12} sm={20} xs={20}>
              <div className="header"></div>
              <div className="form-logo-container">
                <img src={logo} alt="" className="bazar-logo" />
                <p className="welcome-text header-font mb-0">Welcome!</p>
                <p className="instruction sub-header-font">
                  {planName
                    ? "Please check your email for temporary password."
                    : "Please enter your login details."}
                </p>

                {error !== "" && (
                  <Alert
                    style={{ marginBottom: "10px" }}
                    type="error"
                    showIcon={false}
                    message={error}
                    banner
                  />
                )}
                <Form
                  requiredMark={false}
                  layout="vertical"
                  className="login-form w-100"
                  onFinish={(e) => handleLogin(e)}
                  initialValues={{
                    password: tmpPass || "", // Set the initial value for the password field
                  }}
                >
                  <Form.Item
                    label={<span className="sub-header-font">Email</span>}
                    name="userName"
                    className="username-form-item"
                    initialValue={loginData.userName}
                    rules={[
                      { required: true, message: "Please input your email!" },
                      {
                        pattern: emailPattern,
                        message: "Please provide valid Email!",
                      },
                    ]}
                  >
                    <Input
                      autoFocus
                      name="userName"
                      // disabled={loading}
                      disabled={
                        emailToPopulate || invited || loading ? true : false
                      }
                      value={loginData?.userName}
                      className="username"
                      onChange={(e) =>
                        handleState(e.target.name, e.target.value)
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    label={<span className="sub-header-font">Password</span>}
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                    className="password-form-item"
                  >
                    <Input.Password
                      className="password"
                      value={loginData.password}
                      disabled={loading}
                      id="password"
                      key="password"
                      name="password"
                    />
                  </Form.Item>
                  <Link
                    className="forgot-password sub-header-font"
                    to="/forgot-password"
                  >
                    Forgot password?
                  </Link>
                  <Button
                    className="login-button"
                    htmlType="submit"
                    loading={loading}
                  >
                    Login
                  </Button>
                  {/* <Button
                    className="login-google-button"
                    onClick={signInWithGoogle}
                  >
                    <img src={google} alt="" style={{ marginRight: "10px" }} />{" "}
                    Login with google
                  </Button> */}
                  <div className="register-text ">
                    Don’t have an account?{" "}
                    <Link
                      to={
                        dpcDoctor
                          ? `/signup?doctorID=${dpcDoctor}`
                          : procedureName
                          ? `/signup?procedureName=${procedureName}`
                          : planName
                          ? `/signup?employerInvite=${planName}`
                          : "/signup"
                      }
                      className="register-link sub-header-font"
                    >
                      Register Here
                    </Link>
                  </div>
                </Form>
              </div>
            </Col>

            <Col span={24} className="res-login mobile-card hide-version-no">
              <Row justify="center" className="footer">
                <Typography.Text className="footer-text p-font">
                  Version {process.env.REACT_APP_VERSION} © {moment().year()}{" "}
                  <a
                    href="https://delphihc.com/"
                    target="_blank"
                    style={{ color: "inherit" }}
                  >
                    delphihc.com
                  </a>{" "}
                  copyright all rights reserved.
                </Typography.Text>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Login;
