export const SET_TOKEN = "SET_TOKEN";

export const FETCH_DOCTOR_DATA = "FETCH_DOCTOR_DATA";
export const FETCH_SURGERY_DATA = "FETCH_SURGERY_DATA";
export const FETCH_IC_DATA = "FETCH_IC_DATA";
export const FETCH_USER_DATA = "FETCH_USER_DATA";
export const FETCH_USER_ID = "FETCH_USER_ID";
export const LOGOUT = "LOGOUT";
export const FETCH_SPECIALITY = "FETCH_SPECIALITY";
export const FETCH_LAB = "FETCH_LAB";
export const FETCH_PT_CENTER = "FETCH_PT_CENTER";
export const FETCH_PAGE = "FETCH_PAGE";
export const FETCH_APPOINTMENT = "FETCH_APPOINTMENT";
export const FETCH_AREA_OF_SPECIALITY = "FETCH_AREA_OF_SPECIALITY";
export const FETCH_TOUR_STEPS = "FETCH_TOUR_STEPS";
export const FETCH_SURGERY_SPECIALITY = "FETCH_SURGERY_SPECIALITY";
export const FETCH_GLOBAL_SEARCH_DATA = "FETCH_GLOBAL_SEARCH_DATA";
export const FETCH_NOTIFICATION = "FETCH_NOTIFICATION";
export const FETCH_CHAT_COUNT = "FETCH_CHAT_COUNT";
