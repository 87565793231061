// export const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const emailPattern =
  /^(?![^a-zA-Z0-9]+)[a-zA-Z0-9_.,!%&'*+-/=?^{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const namePattern = /^[a-zA-Z0-9]/;
export const specialPatternOnboarding =
  /^[^\s][\w\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]*$/;
export const servicesNamePattern = /^[a-zA-Z0-9][\w&()[\].', -]*$/;

export const userNamePattern = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
export const doctorNameRegex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
// export const streetAddressPattern = /^([a-zA-Z0-9\-.,/ ]+)?$/;
export const streetAddressPattern = /^([a-zA-Z0-9\-.,/][a-zA-Z0-9\s\-.,/]*)?$/;
export const cityPattern = /^[^-\s][a-zA-Z\s\-]*[a-zA-Z\s]$/;
export const zipPattern = /^\d{5}(-\d{4})?$/;
export const ratingPattern = /^(10(\.0)?|[0-9](\.[0-9])?)$/;
export const onlyNumberPattern = /^[0-9]+$/;

export const aboutPattern = /^(?!\s)[\s\S]*$/;
export const tenDigit = /^[0-9]{10}$/g;
export const phonePattern =
  /^\+?([0-9]{1,2})?[-. ]?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

export const iOS = () => {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
};

//Home Page Tour For All User Roles

export const HOME_TOUR_START = "HOME_TOUR_START";
export const HOME_TOUR_TO_SERVICES = "HOME_TOUR_TO_SERVICES";
export const HOME_TOUR_TO_SERVICES_TWO = "HOME_TOUR_TO_SERVICES_TWO";
export const HOME_TOUR_END = "HOME_TOUR_END";

//Tour For Doctor
export const DOCTOR_TOUR_START = "DOCTOR_TOUR_START";
export const DOCTOR_DETAILS_TOUR_START = "DOCTOR_DETAILS_TOUR_START";
export const DOC_PROFILE_TOUR_START = "DOC_PROFILE_TOUR_START";
export const DOCTOR_TOUR_END = "DOCTOR_TOUR_END";

//Tour For Imaging center
export const IMAGING_TOUR_START = "IMAGING_TOUR_START";
export const IMAGING_DETAILS_TOUR_START = "IMAGING_DETAILS_TOUR_START";
export const IMAGING_PROFILE_TOUR_START = "IMAGING_PROFILE_TOUR_START";
export const IMAGING_TOUR_END = "IMAGING_TOUR_END";

//Tour For Lab center
export const LAB_TOUR_START = "LAB_TOUR_START";
export const LAB_DETAILS_TOUR_START = "LAB_DETAILS_TOUR_START";
export const LAB_PROFILE_TOUR_START = "LAB_PROFILE_TOUR_START";
export const LAB_TOUR_END = "LAB_TOUR_END";

//Tour For Infusion center
export const INFUSION_TOUR_START = "INFUSION_TOUR_START";
export const INFUSION_DETAILS_TOUR_START = "INFUSION_DETAILS_TOUR_START";
export const INFUSION_PROFILE_TOUR_START = "INFUSION_PROFILE_TOUR_START";
export const INFUSION_TOUR_END = "INFUSION_TOUR_END";

export const SURGICAL_TOUR_START = "SURGICAL_TOUR_START";
export const SURGICAL_DETAILS_TOUR_START = "SURGICAL_DETAILS_TOUR_START";
export const SURGICAL_PROFILE_TOUR_START = "SURGICAL_PROFILE_TOUR_START";
export const SURGICAL_TOUR_END = "SURGICAL_TOUR_END";
export const SURGICAL_SELECT_DOCTOR_TOUR_END =
  "SURGICAL_SELECT_DOCTOR_TOUR_END";

export const PT_TOUR_START = "PT_TOUR_START";
export const PT_DETAILS_TOUR_START = "PT_DETAILS_TOUR_START";
export const PT_PROFILE_TOUR_START = "PT_PROFILE_TOUR_START";

export const INTERNAL_TOUR_START = "INTERNAL_TOUR_START";
export const INTERNAL_DETAILS_TOUR_START = "INTERNAL_DETAILS_TOUR_START";
export const INTERNAL_PROFILE_TOUR_START = "INTERNAL_PROFILE_TOUR_START";
export const END_TOUR = "END_TOUR";

export const notificationTopics = [
  "doctor",
  "infusionCenter",
  "labCenter",
  "ptCenter",
  "surgicalCenter",
  "imagingCenter",
];

export const formatNumberUS = (number) => {
  if (!number) return "";
  // Remove all non-digit characters from the input value
  const cleanedValue = number?.replace(/\D/g, "");
  // Format the phone number using regex or any desired logic
  let formattedNumber = "";
  if (cleanedValue.length > 0) {
    formattedNumber += `(${cleanedValue.slice(0, 3)}`;
  }
  if (cleanedValue.length > 3) {
    formattedNumber += `) ${cleanedValue.slice(3, 6)}`;
  }
  if (cleanedValue.length > 6) {
    formattedNumber += `-${cleanedValue.slice(6, 10)}`;
  }
  return formattedNumber;
};
// export const urlPattern =
//   /^(?:(?:https?|ftp):\/\/)?(?:www\.)?(?:[\w-]+\.)+[a-z]{2,63}(?:\/[\w-]+)*\/?(?:\?[\w&=]+)?#?(?:[\w-]+)?$/i;

export const urlPattern =
  /^(?:(?:https?|ftp):\/\/)?(?:www\.)?(?:[\w-]+\.)+[a-z]{2,63}(?:\/[\w!#$%&'()*+,.\/:;=?@\[\]-]*)*\/?(?:\?[\w&=]+)?#?(?:[\w-]+)?$/i;

export const urlRegex =
  /^(?:https?:\/\/)?(?:www\.)?(?:[a-z0-9-]+\.)+[a-z]{2,63}(?:\/\S*)?$/i;

export const doctorOptions = [
  { id: "Ankle and Foot", value: "Ankle and Foot" },
  { id: "Eyes", value: "Eyes" },
  { id: "Ear Nose and Throat", value: "Ear Nose and Throat" },
  { id: "Elbow", value: "Elbow" },
  { id: "Hip", value: "Hip" },
  { id: "Hand and Wrist", value: "Hand and Wrist" },
  { id: "Knee", value: "Knee" },
  { id: "Shoulder", value: "Shoulder" },
  { id: "Stomach", value: "Stomach" },
  { id: "Spine and Back", value: "Spine and Back" },
];

export const surgeryMapDoctorSpeciality = {
  "General Surgery": "General Surgeon",
  Gastroenterology: "Gastroenterologist",
  "Neuro Surgeon": "Neuro Surgeon",
  Opthalmology: "Ophthalmologist",
  Otolaryngology: "Otolaryngologists",
  Orthopaedics: "Orthopaedic",
  Physiatrist: "Physiatrist",
  "Pain Management": "Pain Management",
  Podiatry: "Podiatrist",
  "Sports Medicine": "Sports Medicine",
  Urology: "Urology",
};

export const doctorMapSurgerySpeciality = {
  "General Surgeon": "General Surgery",
  Gastroenterologist: "Gastroenterology",
  "Neuro Surgeon": "Neuro Surgeon",
  Ophthalmologist: "Opthalmology",
  Otolaryngologists: "Otolaryngology",
  Orthopaedic: "Orthopaedics",
  Physiatrist: "Physiatrist",
  "Pain Management": "Pain Management",
  Podiatrist: "Podiatry",
  "Sports Medicine": "Sports Medicine",
  Urology: "Urology",
};

export const otolaryngologists = [
  {
    label: "Ear Nose and Throat",
    value: "Ear Nose and Throat",
    key: "Ear Nose and Throat",
  },
];
export const ophthalmologist = [{ label: "Eyes", value: "Eyes", key: "Eyes" }];

export const orthopaedic = [
  { label: "Ankle and Foot", value: "Ankle and Foot", key: "Ankle and Foot" },
  { label: "Elbow", value: "Elbow", key: "Elbow" },
  { label: "Hip", value: "Hip", key: "Hip" },
  { label: "Hand and Wrist", value: "Hand and Wrist", key: "Hand and Wrist" },
  { label: "Head", value: "Head", key: "Head" },
  { label: "Knee", value: "Knee", key: "Knee" },
  { label: "Neck", value: "Neck", key: "Neck" },
  { label: "Shoulder", value: "Shoulder", key: "Shoulder" },
  { label: "Spine and Back", value: "Spine and Back", key: "Spine and Back" },
];

export const gastroenterologist = [
  { label: "Stomach", value: "Stomach", key: "Stomach" },
];

export const pain_management = [
  { label: "Spine and Back", value: "Spine and Back", key: "Spine and Back" },
  { label: "Neck", value: "Neck", key: "Neck" },
];

export const podiatrist = [
  { label: "Ankle and Foot", value: "Ankle and Foot", key: "Ankle and Foot" },
];
export const urology = [{ label: "Internal", value: "Internal" }];

export const neuro_surgeon = [
  { label: "Spine and Back", value: "Spine and Back", key: "Spine and Back" },
  { label: "Head", value: "Head", key: "Head" },
];

export const physiatrist = [
  { label: "Spine and Back", value: "Spine and Back", key: "Spine and Back" },
  { label: "Head", value: "Head", key: "Head" },
  { label: "Neck", value: "Neck", key: "Neck" },
];

export const docSpecialty = [
  { label: "General Surgeon", value: "General Surgeon" },
  { label: "Gastroenterologist", value: "Gastroenterologist" },
  { label: "Neuro Surgeon", value: "Neuro Surgeon" },
  { label: "Otolaryngologists", value: "Otolaryngologists" },
  { label: "Ophthalmologist", value: "Ophthalmologist" },
  { label: "Orthopaedic", value: "Orthopaedic" },
  { label: "Pain Management", value: "Pain Management" },
  { label: "Podiatrist", value: "Podiatrist" },
  { label: "Physiatrist", value: "Physiatrist" },
  { label: "Sports Medicine", value: "Sports Medicine" },
  { label: "Urology", value: "Urology" },
];

export const infusionDrugs = [
  { label: "Actemra", value: "Actemra" },
  { label: "Avsola", value: "Avsola" },
  { label: "Benlysta", value: "Benlysta" },
  { label: "Cimzia", value: "Cimzia" },
  { label: "Cinqair", value: "Cinqair" },
  { label: "Entyvio", value: "Entyvio" },
  { label: "Evenity", value: "Evenity" },
  { label: "Fasenra", value: "Fasenra" },
  { label: "Feraheme", value: "Feraheme" },
  { label: "Ilumya", value: "Ilumya" },
  { label: "Inflectra", value: "Inflectra" },
  { label: "Injectafer", value: "Injectafer" },
  { label: "Krystexxa", value: "Krystexxa" },
  { label: "Lemtrada", value: "Lemtrada" },
  { label: "Nucala", value: "Nucala" },
  { label: "Ocrevus", value: "Ocrevus" },
  { label: "Orencia", value: "Orencia" },
  { label: "Prolastin C Liquid", value: "Prolastin C Liquid" },
  { label: "Prolia", value: "Prolia" },
  { label: "Radicava", value: "Radicava" },
  { label: "Remicade", value: "Remicade" },
  { label: "Renflexis", value: "Renflexis" },
  { label: "Rituxan", value: "Rituxan" },
  { label: "Simponi", value: "Simponi" },
  { label: "Soliris", value: "Soliris" },
  { label: "Solu Medrol", value: "Solu Medrol" },
  { label: "Stelara", value: "Stelara" },
  { label: "Tepezza", value: "Tepezza" },
  { label: "Tremfya", value: "Tremfya" },
  { label: "Truxima", value: "Truxima" },
  { label: "Tysabri", value: "Tysabri" },
  { label: "Venofer", value: "Venofer" },
  { label: "Vyepti", value: "Vyepti" },
  { label: "Xolair", value: "Xolair" },
];

export const surgerySpecialty = [
  { label: "General Surgery", value: "General Surgery" },
  { label: "Gastroenterology", value: "Gastroenterology" },
  { label: "Neuro Surgeon", value: "Neuro Surgeon" },
  { label: "Opthalmology", value: "Opthalmology" },
  { label: "Otolaryngology", value: "Otolaryngology" },
  { label: "Orthopaedics", value: "Orthopaedics" },
  { label: "Physiatrist", value: "Physiatrist" },
  { label: "Pain Management", value: "Pain Management" },
  { label: "Podiatry", value: "Podiatry" },
  { label: "Sports Medicine", value: "Sports Medicine" },
  { label: "Urology", value: "Urology" },
];

export const specialityOptions = [
  {
    value: "Ankle and Foot",
    label: "Ankle and Foot",
  },
  {
    value: "Eyes",
    label: "Eyes",
  },
  {
    value: "Ear Nose and Throat",
    label: "Ear Nose and Throat",
  },
  {
    value: "Elbow",
    label: "Elbow",
  },
  {
    value: "Hip",
    label: "Hip",
  },
  {
    value: "Hand and Wrist",
    label: "Hand and Wrist",
  },
  {
    value: "Knee",
    label: "Knee",
  },
  {
    value: "Shoulder",
    label: "Shoulder",
  },
  {
    value: "Stomach",
    label: "Stomach",
  },
  {
    value: "Spine and Back",
    label: "Spine and Back",
  },
];

export const searchSpecialityOptions = [
  {
    value: "Doctor",
    label: "Doctor",
  },
  {
    value: "Imaging Center",
    label: "Imaging Center",
  },
  {
    value: "Infusion Center",
    label: "Infusion Center",
  },
  {
    value: "Lab",
    label: "Lab",
  },
  {
    value: "Physical Therapy",
    label: "Physical Therapy",
  },
  {
    value: "Surgery Centers",
    label: "Surgery Centers",
  },
];

export const surgicalSpecialityOptions = [
  {
    id: "Ankle and Foot",
    value: "Ankle and Foot",
  },
  {
    id: "Eyes",
    value: "Eyes",
  },
  {
    id: "Ear Nose and Throat",
    value: "Ear Nose and Throat",
  },
  {
    id: "Elbow",
    value: "Elbow",
  },
  {
    id: "Head",
    value: "Head",
  },
  {
    id: "Hand and Wrist",
    value: "Hand and Wrist",
  },
  {
    id: "Hip",
    value: "Hip",
  },
  {
    id: "Knee",
    value: "Knee",
  },
  {
    id: "Leg",
    value: "Leg",
  },
  {
    id: "Neck",
    value: "Neck",
  },
  {
    id: "Shoulder",
    value: "Shoulder",
  },
  {
    id: "Spine and Back",
    value: "Spine and Back",
  },
  {
    id: "Stomach",
    value: "Stomach",
  },
];

export const imagingCenterOptions = [
  { id: "Arthrogram", value: "Arthrogram" },
  { id: "Breast MRI", value: "Breast MRI" },
  { id: "Bone Density", value: "Bone Density" },
  { id: "CT", value: "CT" },
  { id: "Mammogram", value: "Mammogram" },
  { id: "MRI", value: "MRI" },
  { id: "PET", value: "PET" },
  { id: "Ultrasound", value: "Ultrasound" },
  { id: "X-ray", value: "X-ray" },
];

export const labOptions = [
  { id: "Blood Work", value: "Blood Work" },
  { id: "Lipid Panel", value: "Lipid Panel" },
  { id: "Liver Panel", value: "Liver Panel" },
  { id: "Metabolic Panel", value: "Metabolic Panel" },
  { id: "Thyroid Panel", value: "Thyroid Panel" },
];

export const typeOfCare = [
  {
    value: "Surgery",
    label: "Surgery",
  },
  {
    value: "Imaging",
    label: "Imaging",
  },
  {
    value: "Physical Therapy",
    label: "Physical Therapy",
  },
  {
    value: "Labs",
    label: "Labs",
  },
  {
    value: "Infusion",
    label: "Infusion",
  },
  {
    value: "Annual Wellness Check",
    label: "Annual Wellness Check",
  },
  {
    value: "Flu Shot",
    label: "Flu Shot",
  },
];

export function formatCSVData(labelKeyMap, csvData) {
  const headers = Object.entries(labelKeyMap).map(([label, key]) => ({
    label,
    key,
  }));

  const resData = csvData.map((row) =>
    Object.entries(labelKeyMap).reduce((acc, [label, key]) => {
      acc[key] = row[key];
      return acc;
    }, {})
  );

  return { headers, resData };
}

export const handleSearch = async (labData, searchValue, setDataSource) => {
  const filterData = labData.filter((item) => {
    return item.searchByName.indexOf(searchValue.toLowerCase()) >= 0;
  });
  setDataSource(filterData);
};

export const disableSubmitButton =
  (fieldNames) => (form, setSubmitDisabled) => () => {
    const values = form.getFieldsValue(fieldNames);
    setSubmitDisabled(
      !fieldNames.every((fieldName) => values[fieldName]?.trim())
    );
  };

export const docTreeData = [
  {
    title: "General Surgeon",
    value: "General Surgeon",
  },
  {
    title: "Gastroenterologist",
    value: "Gastroenterologist",
    children: [
      {
        title: "Stomach",
        value: "Stomach",
      },
    ],
  },
  {
    title: "Otolaryngologists",
    value: "Otolaryngologists",
    children: [
      {
        title: "Ear Nose and Throat",
        value: "Ear Nose and Throat",
      },
    ],
  },
  {
    title: "Ophthalmologist",
    value: "Ophthalmologist",
    children: [
      {
        title: "Eyes",
        value: "Eyes",
      },
    ],
  },
  {
    title: "Orthopaedic",
    value: "Orthopaedic",
    children: [
      {
        title: "Ankle and Foot",
        value: "Ankle and Foot",
      },
      {
        title: "Elbow",
        value: "Elbow",
      },
      {
        title: "Hip",
        value: "Hip",
      },
      {
        title: "Hand and Wrist",
        value: "Hand and Wrist",
      },
      {
        title: "Head",
        value: "Head",
      },
      {
        title: "Knee",
        value: "Knee",
      },
      {
        title: "Neck",
        value: "Neck",
      },
      {
        title: "Shoulder",
        value: "Shoulder",
      },
      {
        title: "Spine and Back",
        value: "Spine and Back",
      },
    ],
  },
  {
    title: "Pain Management",
    value: "Pain Management",
    children: [
      {
        title: "Spine and Back",
        value: "Spine and Back",
      },
      {
        title: "Neck",
        value: "Neck",
      },
    ],
  },
  {
    title: "Podiatrist",
    value: "Podiatrist",
    children: [
      {
        title: "Ankle and Foot",
        value: "Ankle and Foot",
      },
    ],
  },
  {
    title: "Urology",
    value: "Urology",
    children: [
      {
        title: "Internal",
        value: "Internal",
      },
    ],
  },
];

export const satisfactionLevels = [
  {
    text: "1",
    background: "#FF3838",
  },
  {
    text: "2",
    background: "#FF7575",
  },
  {
    text: "3",
    background: "#F0AA00",
  },
  {
    text: "4",
    background: "#FFB500",
  },
  {
    text: "5",
    background: "#73CE38",
  },
  {
    text: "Very dissatisfied",
    background:
      "linear-gradient(180deg, rgba(255, 117, 117, 0.50) 0%, rgba(217, 217, 217, 0.00) 100%)",
  },
  {
    text: "Partially satisfied",
    background:
      "linear-gradient(180deg, rgba(255, 181, 0, 0.50) 0%, rgba(217, 217, 217, 0.00) 100%)",
  },
  {
    text: "Very satisfied",
    background:
      "linear-gradient(180deg, rgba(115, 206, 56, 0.50) 0%, rgba(217, 217, 217, 0.00) 100%)",
  },
];
