import React, { useState } from "react";
import moment from "moment";

import {
  Modal,
  Button,
  Form,
  Input,
  Col,
  Row,
  DatePicker,
  InputNumber,
  message,
} from "antd";
import {
  emailPattern,
  formatNumberUS,
  namePattern,
  servicesNamePattern,
  tenDigit,
} from "utility/constant";

export default function IndividualInvite({
  title,
  open,
  onOk,
  onCancel,
  inviteTour,
  loading,
  IndInvite,
  onFinishFailed,
  memberForm,
  setInviteTourIndex,
  setInviteIndDependent,
  setInvSuccessModel,
}) {
  const [form] = Form.useForm();

  const [date, setDate] = useState("");
  const [dateValidation, setDateValidation] = useState("");

  const onChangeDatepicker = (date, dateString) => {
    const eighteenYearsAgo = moment().subtract(18, "years");
    const formattedEighteenYearsAgo = eighteenYearsAgo.format("YYYY-MM-DD");

    setDateValidation(formattedEighteenYearsAgo);
    setDate(dateString);
  };

  const handleModalDestroy = () => {
    setDate("");
    setDateValidation("");
    form.resetFields();
  };

  return (
    <Modal
      title={title}
      width={600}
      visible={open}
      onOk={onOk}
      onCancel={onCancel}
      keyboard={false}
      centered
      className="form-modal inv-second-step desired-modal"
      footer={null}
      afterClose={handleModalDestroy}
    >
      <Form
        name="employee"
        onFinish={IndInvite}
        autoComplete="off"
        id="memberInvite"
        layout="vertical"
        form={form}
      >
        <Row gutter={20}>
          <Col span={12} md={12} xs={24} sm={24}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "Please enter first name",
                },
                {
                  pattern: namePattern,
                  message: "Please enter a valid first name",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12} md={12} xs={24} sm={24}>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: "Please enter last name",
                },

                {
                  pattern: namePattern,
                  message: "Please enter a valid last name",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12} md={12} xs={24} sm={24}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter email",
                },
                {
                  pattern: emailPattern,
                  message: "Please enter a valid email",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12} md={12} xs={24} sm={24}>
            <Form.Item
              label="Phone"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please enter phone number",
                },
                {
                  pattern: tenDigit,
                  message: "Please enter a valid phone number",
                },
              ]}
            >
              <InputNumber
                controls={false}
                formatter={(value) => formatNumberUS(value)}
                parser={(value) => {
                  const cleanedValue = value.replace(/\D/g, "");
                  return cleanedValue.slice(0, 10);
                }}
                style={{ width: "100%", borderRadius: "5px" }}
              />
            </Form.Item>
          </Col>

          <Col span={12} md={12} xs={24} sm={24}>
            <Form.Item
              label="Relation"
              name="relation"
              rules={[
                {
                  pattern: servicesNamePattern,
                  message: "Please enter a valid relation",
                },
                {
                  required: true,
                  message: "Please enter relation",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12} md={12} xs={24} sm={24}>
            <Form.Item
              label="Date of Birth"
              name="dob"
              // initialValue={date}
              rules={[
                {
                  required: true,
                  message: "Please select your date of birth",
                },
                {
                  validator: (_, value) => {
                    if (date < dateValidation) {
                      return Promise.resolve(); // Validation passed
                    }
                    return Promise.reject("Users under 18 are not permitted");
                  },
                },
              ]}
            >
              <DatePicker
                onChange={onChangeDatepicker}
                disabledDate={(d) => !d || d.isAfter(new Date())}
                style={{ width: "100%" }}
                format={"MM/DD/YYYY"}
              />
            </Form.Item>
          </Col>

          <Col
            span={12}
            md={24}
            xs={24}
            sm={24}
            style={{ textAlign: "center" }}
          >
            {inviteTour ? (
              <Button
                type="primary"
                className="theme-btn inv-third-step"
                onClick={() => {
                  setTimeout(() => {
                    setInviteTourIndex(3);
                  }, 500);
                  setInviteIndDependent(false);
                  setInvSuccessModel(true);
                }}
                style={{ marginTop: "0px !important" }}
              >
                Send Invite
              </Button>
            ) : (
              <Button
                type="primary"
                className="theme-btn inv-third-step"
                htmlType="submit"
                form="memberInvite"
                key="submit"
                loading={loading}
                style={{ marginTop: "0px !important" }}
              >
                Send Invite
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
