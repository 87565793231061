import {
  Button,
  Col,
  Divider,
  Row,
  Typography,
  Form,
  Input,
  Select,
  DatePicker,
  Radio,
  message,
  Card,
  Skeleton,
  Modal,
  Space,
  InputNumber,
  Tag,
} from "antd";
import copy from "assets/icons/copy.svg";
import moment from "moment";
import React, { useState } from "react";
import BackButton from "commons/BackButton";
import { useLocation } from "react-router-dom";
import { API } from "aws-amplify";
import { useEffect } from "react";
import SkeletonBlock from "utility/SkeletonBlocks";
import SuccessModel from "utility/SuccessModel";
import user from "assets/icons/emptyUser.svg";
import cross from "assets/icons/cross_grey.svg";
import addMore from "assets/icons/addMoreDependent.svg";
import { loadStripe } from "@stripe/stripe-js";
import {
  GetCompanyRegi,
  GetEligibleMemberDetails,
  GetEmployeeDetails,
  GetMemberProfile,
  ListBillingPlans,
  ListCompanyRegis,
  UpdateEligibleMember,
  UpdateEmployeeProfile,
  UpdateEmployeeRegi,
  UpdateMemberProfile,
  UpdateMembers,
} from "utility/action";
import { useCallback } from "react";
import {
  cityPattern,
  emailPattern,
  formatNumberUS,
  phonePattern,
  servicesNamePattern,
  streetAddressPattern,
  tenDigit,
  zipPattern,
} from "utility/constant";
import CopyToClipboard from "react-copy-to-clipboard";
import IndividualInvite from "containers/MainLayout/InviteModals/IndividualInvite";

export default function EmployeeDetail() {
  const stripePromise = loadStripe(`${process.env.REACT_APP_EPC_STRIPE}`);
  const [stripe, setStripe] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [changeLoader, setChangeLoader] = useState(false);
  const [eligibleMember, setEligibleMember] = useState([]);
  const [skeletonLoader, setskeletonLoader] = useState(false);
  const [inviteLoading, setInviteLoading] = useState(false);
  const [employerChangeModal, setEmployerChangeModal] = useState(false);
  const [employerChangePrompt, setEmployerChangePrompt] = useState(false);
  const [addEmp, setAddEmp] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [employers, setEmployers] = useState([]);
  const [employeeProfile, setEmployeProfile] = useState([]);
  const [loading, setLoading] = useState(false);
  const [successModel, setSuccessModel] = useState(false);
  const [invitationModel, setInvitationModel] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [showDpcPlan, setShowDpcPlan] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const [link, setLink] = useState(false);
  const [planChange, setPlanChange] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [inviteIndDependent, setInviteIndDependent] = useState(false);
  const [cancelSub, setCancelSub] = useState(false);
  const [updateSub, setUpdateSub] = useState(false);
  const [cancelSubLoading, setCancelSubLoading] = useState(false);
  const [updateSubLoading, setUpdateSubLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [dependentList, setDependentList] = useState([]);
  const [showDependentList, setShowDependentList] = useState(false);
  const [date, setDate] = useState("");
  const { Option } = Select;
  const location = useLocation();
  const empID = location?.state?.id[0];
  const memberID = location?.state?.id[1];
  const [memberForm] = Form.useForm();
  const [EpcBillingStatus, setEpcBillingStatus] = useState(null);
  const [planPrice, setPlanPrice] = useState([120, 1200]);

  const isDependent = location?.state?.member;
  const isDPC = location?.state?.from === "DPC" ? true : false;
  const handleValuesChange = () => {
    setSaveDisabled(false);
  };

  // for date picker
  const onChangeDatepicker = (date, dateString) => {
    setDate(dateString);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setAddEmp(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setAddEmp(false);
    setCancelSub(false);
  };

  const onCopy = () => {
    message.success("Copied to clipboard!");
  };

  const [btndisabled, setbtndisabled] = useState(true);

  const onValuesChange = (changedValues, allValues) => {
    if (allValues.phone !== undefined && allValues.phone !== "") {
      setbtndisabled(false);
    } else {
      setbtndisabled(true);
    }
  };

  const fetchEmployeeData = useCallback(async () => {
    if (memberID === "HPI") {
      setskeletonLoader(true);
      await GetEligibleMemberDetails(empID)
        .then((response) => {
          setEligibleMember(response);
          if (response?.memberDOB) {
            setDate(moment(response?.memberDOB, "YYYY-MM-DD"));
          } else {
            setDate(null);
          }
        })
        .catch((error) => {
          console.log("error", error);
          setskeletonLoader(false);
        });
      setskeletonLoader(false);
    } else if (isDependent === "Member") {
      setskeletonLoader(true);
      await GetMemberProfile(empID)
        .then((response) => {
          setEmployeeData(response);
          setEmployeProfile(response?.profile);
          if (response?.profile?.dob) {
            setDate(moment(response?.profile?.dob, "YYYY-MM-DD"));
          } else {
            setDate(null);
          }
        })
        .catch((error) => {
          console.log("error", error);
          setskeletonLoader(false);
        });
      setskeletonLoader(false);
    } else {
      setskeletonLoader(true);
      await GetEmployeeDetails(empID)
        .then((response) => {
          setEmployeeData(response);
          setEpcBillingStatus(response?.DpcDetails?.accountStatus);
          setEmployeProfile(response?.profile);
          if (response?.profile?.dob) {
            setDate(moment(response?.profile?.dob, "YYYY-MM-DD"));
          } else {
            setDate(null);
          }
        })
        .catch((error) => {
          console.log("error", error);
          setskeletonLoader(false);
        });

      setskeletonLoader(false);
    }

    setskeletonLoader(false);
  }, [empID, memberID]);

  useEffect(() => {
    if (
      isDPC &&
      isDependent !== "Member" &&
      employeeData?.dpcMembers?.items?.length > 0
    ) {
      const newDependentList = employeeData?.dpcMembers?.items?.map(
        (member) => ({
          name: `${member.firstName} ${member.lastName}`,
          email: member.email,
        })
      );

      setDependentList([...newDependentList]);
      setShowDependentList(true);
    }

    if (
      isDPC &&
      employeeData?.epcCheckoutLink &&
      !employeeData?.DpcDetails &&
      employeeData?.epcCheckoutLink?.expiry * 1000 > Date.now()
    ) {
      setShowLink(true);
      setLink(employeeData?.epcCheckoutLink?.url);
    }

    if (EpcBillingStatus === "ACTIVE") {
      let plan = employeeData?.DpcDetails?.plan?.planDetails;
      plan ? (plan = JSON.parse(plan)) : (plan = null);
      setCurrentPlan(plan?.planName);
    }
  }, [employeeData, EpcBillingStatus]);

  useEffect(() => {
    fetchEmployeeData();
    setSuccessModel(false);
    setInvitationModel(false);
  }, [fetchEmployeeData]);

  const updateEmployee = async (values) => {
    try {
      if (memberID === "HPI") {
        setLoading(true);
        const inputData = {
          id: empID,
          email: values?.email ? values?.email : null,
          memberFirstName: values?.firstName,
          memberGender: values?.gender,
          memberLastName: values?.lastName,
          memberDOB: date ? moment(date).format("YYYY-MM-DD") : undefined,
          phone: values?.phone,
          memberAddress1: values?.streetAdress,
          memberCity: values?.city,
          memberState: values?.state,
          memberZip: values?.zip,
          payer: values?.insuranceCarrier,
        };
        await UpdateEligibleMember(inputData).then((response) => {
          setEligibleMember(response);
        });

        setLoading(false);
        setSuccessModel(true);
        setTimeout(() => {
          setSuccessModel(false);
        }, 3000);
      } else {
        setLoading(true);
        const inputData = Object.assign(
          {
            email: values?.email,
            firstName: values?.firstName,
            gender: values?.gender,
            lastName: values?.lastName,
            dob: date ? moment(date).format("YYYY-MM-DD") : null,
            phone: values?.phone,
            streetAdress: values?.streetAdress,
            city: values?.city,
            state: values?.state,
            zip: values?.zip,
            insuranceCarrier: values?.insuranceCarrier,
          },
          employeeProfile?.editStatus === "REQUESTED" &&
            (values?.insuranceCarrier !== employeeProfile.insuranceCarrier ||
              values?.planID !== employeeProfile.planID ||
              values?.memberID !== employeeProfile.memberID ||
              (date
                ? moment(date).format("YYYY-MM-DD") !== employeeProfile.dob
                : false))
            ? { editStatus: "APPROVED" }
            : {}
        );

        if (isDependent === "Member") {
          const MemberInputData = {
            email: values?.email,
            firstName: values?.firstName,
            gender: values?.gender,
            lastName: values?.lastName,
            dob: date ? moment(date).format("YYYY-MM-DD") : null,
            phone: values?.phone,
            streetAdress: values?.streetAdress,
            city: values?.city,
            state: values?.state,
            zip: values?.zip,
            insuranceCarrier: values?.insuranceCarrier,
          };
          await UpdateMemberProfile(MemberInputData);
        } else {
          await UpdateEmployeeProfile(inputData);
        }

        const employeeDetails = await GetEmployeeDetails(empID);

        const myInit = {
          body: {
            id: empID,
          },
        };
        if (
          values?.role === "ADMIN" &&
          employeeDetails.userType === "CORPORATE"
        ) {
          await API.post("bazarhealthREST", `/hr/make_admin`, myInit);
        } else if (
          values?.role === "MEMBER" &&
          employeeDetails.userType === "CORPORATE_ADMIN"
        ) {
          await API.post("bazarhealthREST", `/hr/make_employee`, myInit);
        }

        if (
          (values?.insuranceCarrier !== employeeProfile.insuranceCarrier ||
            values?.planID !== employeeProfile.planID ||
            values?.memberID !== employeeProfile.memberID ||
            (date
              ? moment(date).format("YYYY-MM-DD") !== employeeProfile.dob
              : false)) &&
          employeeProfile.editStatus === "REQUESTED"
        ) {
          try {
            const editRequestData = {
              body: {
                email: values?.email,
                firstName: values?.firstName,
              },
            };

            await API.post(
              "bazarhealthREST",
              `/superAdmin/approveProfileChanges`,
              editRequestData
            );
          } catch (error) {
            messageApi.open({
              type: "error",
              content: "Something went wrong",
            });
          }
        }

        setLoading(false);
        setSuccessModel(true);
        fetchEmployeeData();
        setTimeout(() => {
          setSuccessModel(false);
        }, 3000);
      }
    } catch (error) {
      setLoading(false);

      messageApi.open({
        type: "error",
        content: "Error.",
      });
    }
  };

  const cancelSubscription = async () => {
    setCancelSubLoading(true);

    let url = `/billing/cancelSubscriptionImmediate`;
    console.log(employeeData, employeeData);
    const myInit = {
      body: {
        stripeSubscriptionId: employeeData?.DpcDetails?.stripeSubscriptionID,
      },
    };
    await API.post("bazarhealthREST", url, myInit);
    messageApi.open({
      type: "success",
      content: "Subscription Cancelled Successfully",
    });
    setCancelSub(false);
    setEpcBillingStatus(null);
    setCancelSubLoading(false);
  };

  const fetchEmployersData = useCallback(async () => {
    await ListCompanyRegis()
      .then((response) => {
        setEmployers(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleRemove = async (indexToRemove) => {
    messageApi.open({
      type: "progress",
      content: `Removing Dependent ${dependentList[indexToRemove]?.email}`,
    });

    let url = `/employee/removeDpcDep`;
    try {
      const myInit = {
        body: {
          email: dependentList[indexToRemove]?.email,
        },
      };
      await API.post("bazarhealthREST", url, myInit);

      const updatedList = dependentList.filter(
        (_, index) => index !== indexToRemove
      );
      setDependentList(updatedList);
    } catch (e) {
      messageApi.open({
        type: "error",
        content: `${e?.response?.data?.message}`,
      });
    }
  };
  useEffect(() => {
    fetchEmployersData();
  }, [fetchEmployersData]);

  const IndInvite = async (values) => {
    setLoading(true);
    let url = `/employee/inviteDpcDep`;
    try {
      const myInit = {
        body: {
          email: values?.email,
          employeeID: employeeData?.id,
          firstName: values?.firstName,
          lastName: values?.lastName,
          relation: values?.relation,
          phone: values?.phone,
          dob: moment(values?.dob).format("YYYY-MM-DD"),
          doctorID: employeeData?.dpcDoctorID,
        },
      };

      await API.post("bazarhealthREST", url, myInit);

      const newDependent = {
        name: `${values?.firstName} ${values?.lastName}`,
        email: values?.email,
      };

      memberForm.setFieldsValue({
        dob: null, // Set the date of birth field to null or an initial value
      });

      // Reset other fields
      memberForm.resetFields();
      // memberForm.resetFields();

      setDependentList([newDependent, ...dependentList]);
      setShowDependentList(true);
      setLoading(false);
      setInviteIndDependent(false);
      setSuccessModel(true);
    } catch (e) {
      messageApi.open({
        type: "error",
        content: `${e?.response?.data?.message}`,
      });
      setLoading(false);
      return { error: "Failed to fetch session" };
    }
  };

  const initialValues = {
    email: employeeProfile?.email
      ? employeeProfile?.email
      : employeeData?.emailID,
    firstName: employeeProfile?.firstName
      ? employeeProfile?.firstName
      : employeeData?.firstName,
    lastName: employeeProfile?.lastName
      ? employeeProfile?.lastName
      : employeeData?.lastName,
    phone: employeeProfile?.phone,
    streetAdress: employeeProfile?.streetAdress,
    city: employeeProfile?.city,
    state: employeeProfile?.state,
    zip: employeeProfile?.zip,
    insuranceCarrier: employeeProfile?.insuranceCarrier,
    gender: employeeProfile?.gender,
    role: employeeData?.userType === "CORPORATE_ADMIN" ? "ADMIN" : "MEMBER",
  };

  const memberInitialValues = {
    email: eligibleMember?.email,
    firstName: eligibleMember?.memberFirstName,
    lastName: eligibleMember?.memberLastName,
    phone: eligibleMember?.phone,
    streetAdress: eligibleMember?.memberAddress1,
    city: eligibleMember?.memberCity,
    state: eligibleMember?.memberState,
    zip: eligibleMember?.memberZip,
    insuranceCarrier: eligibleMember?.payer,
    gender: eligibleMember?.memberGender,
  };

  const sendInvitation = async () => {
    try {
      setInviteLoading(true);
      const myInit = {
        body: {
          id: empID,
        },
      };

      if (memberID === "HPI") {
        await API.post(
          "bazarhealthREST",
          `/superAdmin/send_invite_members`,
          myInit
        );
      } else {
        await API.post(
          "bazarhealthREST",
          `/superAdmin/resend_invite_members`,
          myInit
        );
      }

      setInviteLoading(false);
      setInvitationModel(true);
      setTimeout(() => {
        setInvitationModel(false);
      }, 3000);
      fetchEmployeeData();
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Error Sending Invite..",
      });
      setInviteLoading(false);
    }
  };

  const changeEmployer = async (values) => {
    try {
      setChangeLoader(true);
      const response = await GetCompanyRegi(values?.employerName);
      if (
        employeeData?.companyID?.name === values?.employerName ||
        employeeData?.companyID?.name === response?.name
      ) {
        return messageApi.open({
          key: "member",
          type: "warning",
          content: `Already assigned to the selected company!`,
          duration: 5,
        });
      }

      const activeAdmins =
        employeeData?.companyID?.profile?.adminDetails.filter((admin) => {
          const parsedAdmin = JSON.parse(admin);
          return parsedAdmin.lastLogin !== null;
        });

      if (
        activeAdmins?.length === 1 &&
        employeeData?.userType === "CORPORATE_ADMIN"
      ) {
        return messageApi.open({
          key: "member",
          type: "warning",
          content: `There must be at least one Admin in any employer!`,
          duration: 5,
        });
      }

      const inputData = {
        id: empID,
        companyRegiEmployeeId: response?.id,
        billing_status: response?.billing_status,
        accountStatus: response?.accountStatus,
        stripeCustomerID: response?.stripeCustomerID,
        stripeSubscriptionID: response?.stripeSubscriptionID,
        stripePriceID: response?.stripePriceID,
        planID: response?.planID,
        verified: response.billing_status === "NOT_REQUIRED" ? true : false,
      };

      const IndividualData = {
        id: empID,
        companyRegiEmployeeId: response?.id,
        billing_status: response?.billing_status,
        accountStatus: response?.accountStatus,
        stripeCustomerID: response?.stripeCustomerID,
        stripeSubscriptionID: response?.stripeSubscriptionID,
        stripePriceID: response?.stripePriceID,
        planID: response?.planID,
        userType: "CORPORATE",
        verified: response.billing_status === "NOT_REQUIRED" ? true : false,
      };
      await UpdateEmployeeRegi(addEmp === true ? IndividualData : inputData);

      const memberData = employeeData?.members?.items;
      for (let i = 0; i < memberData.length; i++) {
        const memberInput = {
          id: memberData[i].id,
          billing_status: response?.billing_status,
          accountStatus: response?.accountStatus,
          companyRegiMemberId: response?.id,
          stripeSubscriptionID: response?.stripeSubscriptionID,
        };
        await UpdateMembers(memberInput);
      }

      setAddEmp(false);
      fetchEmployeeData();
      setIsModalOpen(false);
      setEmployerChangeModal(true);
      setTimeout(() => {
        setEmployerChangeModal(false);
      }, 3000);
    } catch (error) {
      console.log(error);
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
    } finally {
      setChangeLoader(false);
    }
  };

  const onPlanChange = (e) => {
    setPlanChange(e.target.value);
    setSubmitDisabled(false);
  };

  const getOrgCheckoutSession = async (planId, stripePriceId, values) => {
    let url = `/billing/generatePaymentForEPC`;
    try {
      const myInit = {
        body: {
          stripePriceId,
          accountId: employeeData?.id,
          email: employeeData?.emailID,
          planId,
        },
      };
      console.log(myInit);
      let result = await API.post("bazarhealthREST", url, myInit);
      console.log(result, "result");
      return result;
    } catch (e) {
      console.log("error retrieving data", e);
      return { error: "Failed to fetch session" };
    }
  };

  const createStripeSession = async (planId, stripePriceId) => {
    try {
      const sessionResult = await getOrgCheckoutSession(planId, stripePriceId);
      console.log(sessionResult, "sessionRsult");
      return sessionResult;
      // if (sessionResult.type === "checkout" && sessionResult.sessionId) {
      //   stripe
      //     .redirectToCheckout({
      //       sessionId: sessionResult.sessionId,
      //     })
      //     .catch((e) => {
      //       console.log(e);
      //     });
      // } else {
      //   console.log("err");
      // }
    } catch (error) {
      console.log("error creating stripe session", error);
    }
  };

  const handleEPCPLan = async (filterCriteria) => {
    // setLoading(true);
    setLoading(true);
    if (updateSub) {
      setLoading(true);

      setTimeout(() => {
        messageApi.open({
          type: "success",
          content: "Subscription Updated Successfully",
        });
        setUpdateSub(false);
        setLoading(false);
        setPlanChange("Annualy");
      }, 5000);
    } else {
      //calling the checkout and setup API
      console.log("hfb");
      try {
        const res = await ListBillingPlans(filterCriteria);
        const planArray = await Promise.all(
          res?.filter((param) => {
            const details = JSON.parse(param?.planDetails);
            return details?.planName === planChange;
          })
        );
        console.log(planArray, "planarray");
        const obj = JSON.parse(planArray[0].planDetails);
        console.log(obj.stripePriceID, planArray[0].id);
        const sessionResult = await createStripeSession(
          planArray[0].id,
          obj?.stripePriceId
        );
        console.log(sessionResult, "sessionResult");
        fetchEmployeeData();
      } catch (err) {
        console.log(err);
      }
      // const inputData = {
      //   input: {
      //     id: "b079967a-83c2-4f64-81de-78f7c418c925",
      //     doctorID: "a0565149-bb97-4fc7-a56a-6397de8a2dd4",
      //     planID: "774c09ed-ba2c-471d-9da4-0f926e41fc08",
      //     stripeCustomerID: "cus_POEcNeYXMglhax",
      //     stripePriceID: "price_1OH4PXD15HGf4DZkDJ4cTJyg",
      //     stripeSubscriptionID: "sub_1OZSSVD15HGf4DZkN86gFgbB",
      //     accountStatus: "ACTIVE",
      //     metadata: undefined,
      //   },
      // };
      // const responce = await API.graphql({
      //   query: updateDpcDetails,
      //   variables: inputData,
      //   authMode: "AMAZON_COGNITO_USER_POOLS",
      // });

      // console.log(responce);
    }
    setLoading(false);
    // setShowLink(true);
  };

  const EPCCancelComp = () => {
    return (
      <div
        className="mb-20"
        style={{
          border: "1px dashed #000",
          borderRadius: "6px",
          padding: "20px 10px 20px 10px",
          textAlign: "center",
        }}
      >
        <span className="referral-code">Are you sure?</span>
        <Divider />
        <Space size={"small"}>
          <Button
            className="theme-btn"
            htmlType="submit"
            loading={loading}
            onClick={handleEPCPLan}
          >
            Yes
          </Button>
          <Button
            className="theme-btn"
            htmlType="submit"
            loading={loading}
            style={{ background: "grey", color: "black" }}
          >
            No
          </Button>
        </Space>
      </div>
    );
  };

  useEffect(() => {
    if (dependentList.length > 0) {
      setPlanPrice([
        120 + dependentList.length * 108,
        1200 + dependentList.length * 1080,
      ]);
    }
  }, [dependentList]);

  const renderDependentList = () => {
    return (
      <>
        <Divider />
        <span style={{ display: "flex" }}>Dependents</span>

        <Col md={24} className="mt-10 mb-10 d-flex ">
          <Col md={3} className="dependent-avt align-top">
            <img
              src={addMore}
              alt="dep"
              onClick={() => setInviteIndDependent(true)}
            />
            <p>Add More</p>
          </Col>

          <div className="scroll-avt">
            {dependentList?.map((dependent, index) => (
              <Col key={index} md={3} className="dependent-avt ">
                <img
                  src={cross}
                  className="cancel"
                  alt=""
                  onClick={() => handleRemove(index)}
                />
                <img src={user} alt="dep" />

                <p className="break-after-12"> {dependent.name}</p>
                <span className="break-after-12">{dependent.email}</span>
              </Col>
            ))}
          </div>
        </Col>
      </>
    );
  };
  const renderEPCPlan = () => {
    return (
      <div
        className="referral-code mb-20"
        style={{
          border: "1px dashed #000",
          borderRadius: "6px",
          padding: "20px 10px 20px 10px",
          textAlign: "center",
        }}
      >
        <span className="referral-code">
          {" "}
          {updateSub ? "Update Subscription" : "Generate a payment link"}
        </span>

        <Row
          // gutter={400}
          className="mt-20 billing-cards-invite-dpc"
          style={{
            display: "flex",
            width: "100% !important",
            justifyContent: "center",
          }}
        >
          <Col span={6} md={6} xs={24} sm={24} className="column-content">
            <h4> Monthly</h4>
            <Divider />
            <Col
              md={24}
              span={24}
              className="mt-10 d-flex justify-content-center emp-radio"
            >
              <Radio
                value="DPC_MONTHLY"
                onChange={onPlanChange}
                checked={planChange === "DPC_MONTHLY"}
              >
                <span className={`pricing `}> ${planPrice[0]}</span> / month
              </Radio>
            </Col>

            <Col
              span={24}
              className="d-flex justify-content-center align-items-center"
            ></Col>
          </Col>

          <Col span={6} md={6} xs={24} sm={24}>
            <h4>Annually</h4>
            <Divider />
            <Col
              md={24}
              span={24}
              className="mt-10 d-flex justify-content-center emp-radio"
            >
              <Radio
                value="DPC_ANNUALLY"
                onChange={onPlanChange}
                checked={planChange === "DPC_ANNUALLY"}
              >
                <span className={`pricing `}>${planPrice[1]}</span> / year
              </Radio>
            </Col>

            <Col
              span={24}
              className="d-flex justify-content-center align-items-center"
            ></Col>
          </Col>
        </Row>

        <Col
          span={12}
          md={24}
          xs={24}
          sm={24}
          style={{ textAlign: "center", marginTop: "20px" }}
        >
          <Button
            className="theme-btn"
            htmlType="submit"
            loading={loading}
            // disabled={updateSub && planChange === "DPC_MONTHLY"}
            disabled={updateSub} //Currently disabling, as we are yet to work on update
            onClick={() => handleEPCPLan({ name: { contains: "DPC" } })}
          >
            {updateSub ? "Update Subscription" : "Generate"}
          </Button>
        </Col>

        {showDependentList ? (
          renderDependentList()
        ) : (
          <>
            <Divider />
            <Col
              md={24}
              span={24}
              className="mt-10 d-flex justify-content-center emp-radio"
            >
              <Button
                type="primary"
                className="theme-btn w-100 dependent"
                onClick={() => setInviteIndDependent(true)}
                // disabled={submitDisabled ? true : false}
              >
                Add Dependent
              </Button>
            </Col>
          </>
        )}
      </div>
    );
  };

  const selectPlanComp = () => {
    return (
      <div
        onClick={() => setShowDpcPlan(true)}
        className="d-flex justify-content-center"
        style={{
          border: "1px dashed #000",
          padding: "12px",
          borderRadius: "6px",
          margin: "0px 0px 24px 0px",
        }}
      >
        <span className="referral-code">Click here to select a plan</span>
      </div>
    );
  };

  const stripeRenderEPC = () => {
    if (EpcBillingStatus === "ACTIVE") {
      if (cancelSub) {
        return (
          <div
            className="mb-20"
            style={{
              border: "1px dashed #000",
              borderRadius: "6px",
              padding: "20px 10px 20px 10px",
              textAlign: "center",
            }}
          >
            <span className="referral-code">Are you sure?</span>
            <p
              style={{
                fontWeight: "800",
                color: "grey",
                margin: "10px 0 -10px 0",
              }}
            >
              Clicking on YES will cancel the subscription for Enhanced Primary
              Plan
            </p>

            <Divider />
            <Space size={"small"}>
              <Button
                className="theme-btn"
                htmlType="submit"
                loading={cancelSubLoading}
                onClick={cancelSubscription}
                disabled={true} //Currently we are yet to work on the cancel part so making it disable by default.
              >
                YES
              </Button>
              <Button
                className="theme-btn"
                htmlType="submit"
                loading={loading}
                onClick={handleCancel}
                style={{ background: "grey", color: "black" }}
              >
                NO
              </Button>
            </Space>
          </div>
        );
      } else if (updateSub) {
        return renderEPCPlan();
      } else {
        return (
          <div
            className="mb-20"
            style={{
              border: "1px dashed #000",
              borderRadius: "6px",
              padding: "20px 10px 20px 10px",
              textAlign: "center",
            }}
          >
            <span className="referral-code">
              Subscribed to{" "}
              {currentPlan === "DPC_ANNUALLY"
                ? "Annual Plan."
                : "Monthly Plan."}
            </span>
            <Divider />
            <Space size={"small"}>
              <Button
                className="theme-btn"
                htmlType="submit"
                disabled={true} // currently disabling as we are yet to work
                loading={loading}
                onClick={() => {
                  setUpdateSub(true);
                  if (!planChange) {
                    setPlanChange("Monthly");
                  }
                }}
              >
                Update subscription
              </Button>
              <Button
                className="theme-btn"
                htmlType="submit"
                onClick={() => setCancelSub(true)}
                style={{ background: "grey", color: "black" }}
              >
                Cancel subscription
              </Button>
            </Space>
          </div>
        );
      }
    } else {
      return !showLink ? (
        showDpcPlan ? (
          renderEPCPlan()
        ) : (
          selectPlanComp()
        )
      ) : (
        <>
          {/* <Row className="gap-10 mt-30 my-2"> */}
          <Row justify="center" className="update-form">
            <Col span={16} md={16} xs={24} sm={24}>
              <div
                className="referral-code mb-20"
                style={{
                  border: "1px dashed #000",
                  borderRadius: "6px",
                  padding: "20px 10px 20px 10px",
                  textAlign: "center",
                }}
              >
                <span className="referral-code">Generated payment link</span>

                <Row className="gap-10 mtr-20 my-2 admin-forth-step copy-link">
                  <Col span={24} style={{ display: "flex" }}>
                    <Input defaultValue={link} disabled />
                    <CopyToClipboard
                      text={link}
                      onCopy={() => console.log("COpied to CLipboard")}
                    >
                      <Button
                        className="theme-btn"
                        onClick={onCopy}
                        style={{ marginLeft: "5px" }}
                      >
                        Copy
                      </Button>
                    </CopyToClipboard>
                  </Col>
                </Row>
                {showDependentList ? renderDependentList() : <></>}
              </div>
            </Col>
          </Row>
        </>
      );
    }
  };

  const renderDependent = () => {
    if (showDependentList && !showDpcPlan) {
      console.log("1125");
      return renderDependentList();
    }
    return (
      <Col
        md={24}
        span={24}
        className="mt-10 d-flex justify-content-center emp-radio"
      >
        <Button
          type="primary"
          className="theme-btn w-100 dependent"
          onClick={() => setInviteIndDependent(true)}
          // disabled={submitDisabled ? true : false}
        >
          Add Dependent
        </Button>
      </Col>
    );
  };

  return (
    <>
      {contextHolder}
      <Row justify="space-between">
        <BackButton />
        <Typography.Title level={4} className="text-center mb-0">
          {skeletonLoader
            ? ""
            : memberID === "HPI"
            ? eligibleMember?.memberFirstName +
              " " +
              eligibleMember?.memberLastName
            : employeeData?.profile?.firstName
            ? employeeData?.profile?.firstName +
              " " +
              employeeData?.profile?.lastName
            : ""}
        </Typography.Title>
        <Button style={{ marginLeft: 60 }}></Button>
        <Divider style={{ margin: "8px 0" }} />
      </Row>

      <Row justify="center" className="update-form">
        <Col span={16} md={16} xs={24} sm={24}>
          {skeletonLoader ? (
            <>
              <Row gutter={[20, 20]} className="mt-3">
                <SkeletonBlock style={{ width: 700, height: 50 }} />
                <SkeletonBlock style={{ width: 700, height: 50 }} />
                <SkeletonBlock style={{ width: 700, height: 50 }} />
                <SkeletonBlock style={{ width: 700, height: 50 }} />
                <SkeletonBlock style={{ width: 700, height: 50 }} />
                <SkeletonBlock style={{ width: 700, height: 50 }} />
                <SkeletonBlock style={{ width: 700, height: 200 }} />
              </Row>
            </>
          ) : (
            <>
              {isDPC && !isDependent && stripeRenderEPC()}
              {isDPC &&
                !isDependent &&
                !showDpcPlan &&
                EpcBillingStatus !== "ACTIVE" &&
                !showLink &&
                renderDependent()}
              {employeeData?.group === "Member" && !isDPC ? (
                //Adding resend for member and employer namme
                <div className="employer-card">
                  <Card
                    className="doctor-profile bottom-padding"
                    style={{
                      border: "1px solid #A9C8DD",
                      borderStyle: "dashed",
                    }}
                  >
                    <div className="doctor-overview">
                      <Row className="overview-details">
                        {skeletonLoader ? (
                          <Skeleton paragraph={{ rows: 10 }}></Skeleton>
                        ) : (
                          <>
                            <Row justify="space-between table-header w-100">
                              <Row align="middle">
                                <Typography.Title
                                  level={4}
                                  style={{ marginBottom: 0 }}
                                >
                                  Employer :{" "}
                                  {memberID === "HPI"
                                    ? eligibleMember.GroupName
                                    : employeeData?.companyID?.name
                                    ? employeeData?.companyID?.name
                                    : "-"}
                                </Typography.Title>
                              </Row>

                              <Row className="gap-10 mtr-20">
                                {memberID === "HPI" ? (
                                  <>
                                    <Button
                                      className="theme-btn"
                                      onClick={sendInvitation}
                                      loading={inviteLoading}
                                      disabled={
                                        eligibleMember?.email ? false : true
                                      }
                                    >
                                      Send Invite
                                    </Button>
                                  </>
                                ) : employeeData?.lastLogin === null ? (
                                  <>
                                    <Button
                                      className="theme-btn"
                                      onClick={sendInvitation}
                                      loading={inviteLoading}
                                    >
                                      Resend Invite
                                    </Button>
                                  </>
                                ) : (
                                  ""
                                )}
                              </Row>
                            </Row>
                          </>
                        )}
                      </Row>
                    </div>
                  </Card>
                </div>
              ) : employeeData?.userType === "INDIVIDUAL" && !isDPC ? (
                <Row justify="end w-100" style={{ justifyContent: "end" }}>
                  <Row align="center-btn">
                    <Button
                      className="blue-border-btn"
                      onClick={() => {
                        setIsModalOpen(true);
                        setAddEmp(true);
                      }}
                    >
                      Add Employer
                    </Button>
                  </Row>
                </Row>
              ) : !isDPC ? (
                <div className="employer-card">
                  <Card
                    className="doctor-profile bottom-padding"
                    style={{
                      border: "1px solid #A9C8DD",
                      borderStyle: "dashed",
                    }}
                  >
                    <div className="doctor-overview">
                      <Row className="overview-details">
                        {skeletonLoader ? (
                          <Skeleton paragraph={{ rows: 10 }}></Skeleton>
                        ) : (
                          <>
                            <Row justify="space-between table-header w-100">
                              <Row align="middle">
                                <Typography.Title
                                  level={4}
                                  style={{ marginBottom: 0 }}
                                >
                                  Employer :{" "}
                                  {memberID === "HPI"
                                    ? eligibleMember.GroupName
                                    : employeeData?.companyID?.name
                                    ? employeeData?.companyID?.name
                                    : "-"}
                                </Typography.Title>
                              </Row>

                              <Row
                                className="gap-10 mtr-20"
                                style={{ marginLeft: "Auto" }}
                              >
                                {memberID === "HPI" ||
                                employeeData?.lastLogin === null ? (
                                  ""
                                ) : (
                                  <Button
                                    className="blue-border-btn"
                                    onClick={() =>
                                      setEmployerChangePrompt(true)
                                    }
                                  >
                                    Change Employer
                                  </Button>
                                )}
                              </Row>

                              <Row className="gap-10 mtr-20">
                                {memberID === "HPI" ? (
                                  <>
                                    <Button
                                      className="theme-btn"
                                      onClick={sendInvitation}
                                      loading={inviteLoading}
                                      disabled={
                                        eligibleMember?.email ? false : true
                                      }
                                    >
                                      Send Invite
                                    </Button>
                                  </>
                                ) : employeeData?.lastLogin === null ? (
                                  <>
                                    <Button
                                      className="theme-btn"
                                      onClick={sendInvitation}
                                      loading={inviteLoading}
                                    >
                                      Resend Invite
                                    </Button>
                                  </>
                                ) : (
                                  ""
                                )}
                              </Row>
                            </Row>
                          </>
                        )}
                      </Row>
                    </div>
                  </Card>
                </div>
              ) : (
                ""
              )}
              <Form
                name="basic"
                initialValues={
                  memberID === "HPI" ? memberInitialValues : initialValues
                }
                onFinish={updateEmployee}
                // form={form}
                autoComplete="off"
                layout="vertical"
                className="mt-20"
                onValuesChange={handleValuesChange}
                // onValuesChange={onValuesChange}
              >
                <Row gutter={20}>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="First name"
                      name="firstName"
                      rules={[
                        {
                          pattern: servicesNamePattern,
                          message: "Please enter a valid first name",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Last name"
                      name="lastName"
                      rules={[
                        {
                          pattern: servicesNamePattern,
                          message: "Please enter a valid last name",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        {
                          pattern: emailPattern,
                          message: "Please enter a valid email",
                        },
                      ]}
                    >
                      <Input
                        disabled={
                          eligibleMember?.email
                            ? true
                            : memberID === "HPI"
                            ? false
                            : true
                        }
                      />
                    </Form.Item>
                  </Col>
                  {memberID === "HPI" ||
                  employeeData?.userType === "INDIVIDUAL" ||
                  employeeData?.group === "Member" ? (
                    ""
                  ) : (
                    <>
                      {" "}
                      <Col span={12} md={12} xs={24} sm={24}>
                        <Form.Item
                          label="Role"
                          name="role"
                          rules={[
                            {
                              required: true,
                              message: "Please select the role",
                            },
                          ]}
                        >
                          <Radio.Group>
                            <Radio value="ADMIN">Admin</Radio>
                            <Radio value="MEMBER">Member</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </>
                  )}

                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Phone"
                      name="phone"
                      rules={[
                        {
                          pattern: tenDigit,
                          message: "Please enter a valid phone number",
                        },
                      ]}
                    >
                      <InputNumber
                        controls={false}
                        formatter={(value) => formatNumberUS(value)}
                        parser={(value) => {
                          const cleanedValue = value.replace(/\D/g, "");
                          return cleanedValue.slice(0, 10);
                        }}
                        style={{ width: "100%", borderRadius: "5px" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Date of birth"
                      name="dob"
                      initialValue={date}
                    >
                      <DatePicker
                        onChange={onChangeDatepicker}
                        disabledDate={(d) => !d || d.isAfter(new Date())}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item label="Gender" name="gender">
                      <Select
                        placeholder="Select Gender"
                        allowClear
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        <Option value="MALE">Male</Option>
                        <Option value="FEMALE">Female</Option>
                        <Option value="OTHER">Other</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Street address"
                      name="streetAdress"
                      rules={[
                        {
                          pattern: streetAddressPattern,
                          message: "Please enter a valid street address",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="City"
                      name="city"
                      rules={[
                        {
                          pattern: cityPattern,
                          message: "Please enter a valid city name",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="State"
                      name="state"
                      rules={[
                        {
                          pattern: cityPattern,
                          message: "Please enter a valid state name",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Zip code"
                      name="zip"
                      rules={[
                        {
                          pattern: zipPattern,
                          message: "Please enter a valid ZIP code",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Insurance carrier"
                      name="insuranceCarrier"
                      rules={[
                        {
                          pattern: /^[a-zA-Z0-9]+$/,
                          message: "Please enter a valid insurance carrier",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  {/* <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Plan id"
                      name="planID"
                      rules={[
                        {
                          pattern: /^[a-zA-Z0-9]+$/,
                          message: "Please enter a valid plan id",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Member id"
                      name="memberID"
                      rules={[
                        {
                          pattern: /^[a-zA-Z0-9]+$/,
                          message: "Please enter a valid member id",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col> */}

                  <Col
                    span={12}
                    md={24}
                    xs={24}
                    sm={24}
                    style={{ textAlign: "center" }}
                  >
                    <Button
                      size="large"
                      className="theme-btn"
                      htmlType="submit"
                      loading={loading}
                      disabled={saveDisabled}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            </>
          )}
        </Col>
      </Row>

      <SuccessModel
        message="Member Details Updated Successfully!"
        // open={successModel}
      />
      <SuccessModel
        message="Invitation Sent Successfully!"
        open={invitationModel}
      />
      <SuccessModel
        message="Employer changed successfully"
        open={employerChangeModal}
      />

      {/* Change Employer Modal */}
      <Modal
        className="form-modal employer-app-card"
        centered
        footer={null}
        title={
          <Typography.Title level={3}>
            {addEmp === true ? "Add" : "Change"} Employer
          </Typography.Title>
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          name="basic"
          onFinish={changeEmployer}
          autoComplete="off"
          layout="vertical"
        >
          <Row>
            <Col span={12} md={24} xs={24} sm={24}>
              <Form.Item
                label="Employer"
                name="employerName"
                initialValue={
                  memberID === "HPI"
                    ? eligibleMember.GroupName
                    : employeeData?.companyID?.name
                    ? employeeData?.companyID?.name
                    : null
                }
                rules={[
                  {
                    required: true,
                    message: "Please select any employer",
                  },
                ]}
              >
                <Select allowClear placeholder="Select Employer">
                  {employers.map((item) => (
                    <Select.Option
                      key={item?.id}
                      value={item.id}
                    >{`${item.name}`}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col
              span={12}
              md={24}
              xs={24}
              sm={24}
              style={{ textAlign: "center" }}
            >
              <Space>
                <Button
                  type="primary"
                  className="theme-btn grey-color"
                  onClick={() => setIsModalOpen(false)}
                  style={{ width: "106px" }}
                >
                  Cancel
                </Button>
                <Button
                  className="theme-btn"
                  htmlType="submit"
                  loading={changeLoader}
                  style={{ width: "106px" }}
                >
                  Save
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>

      {/* Change Employer Yes No Model */}
      <Modal
        title="Basic Modal"
        open={employerChangePrompt}
        onOk={() => setEmployerChangePrompt(false)}
        centered
        width={400}
        closable={false}
        onCancel={() => setEmployerChangePrompt(false)}
        className="reward-success-modal employer-app-card-prompt"
        footer={null}
      >
        <h6>Are you sure want to change employer?</h6>

        <Space>
          <Button
            type="primary"
            className="theme-btn grey-color"
            onClick={() => setEmployerChangePrompt(false)}
            style={{ width: "106px" }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            className="theme-btn"
            onClick={() => {
              setEmployerChangePrompt(false);
              setIsModalOpen(true);
            }}
            style={{ width: "106px" }}
          >
            Yes
          </Button>
        </Space>
      </Modal>

      <SuccessModel
        message="Dependent Added Successfully!"
        open={successModel}
      />

      <IndividualInvite
        title="Add your Dependent"
        open={inviteIndDependent}
        onOk={() => setInviteIndDependent(false)}
        onCancel={() => setInviteIndDependent(false)}
        loading={loading}
        IndInvite={IndInvite}
        memberForm={memberForm}
      />
    </>
  );
}
