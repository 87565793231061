import React, {  } from "react";
import "./MainLayout.less";
import AdminLayout from "./AdminLayout";
import UserLayout from "./UserLayout";
import { useSelector } from "react-redux";

export default function MainLayout(props) {

  const userData = useSelector((state) => state.userReducer?.userData);

  return (
    <>
      {userData?.userType === "SUPERADMIN" ? (
        <AdminLayout props={props} />
      ) : (
        <UserLayout props={props} />
      )}
    </>
  );
}
