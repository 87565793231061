/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const nearByDoctors = /* GraphQL */ `
  query NearByDoctors(
    $location: LocationInput!
    $areaOfSpeciality: [String]
    $specialty: String
    $surgeryCenter: String
    $mi: Int
    $limit: Int
    $nextToken: String
  ) {
    nearByDoctors(
      location: $location
      areaOfSpeciality: $areaOfSpeciality
      specialty: $specialty
      surgeryCenter: $surgeryCenter
      mi: $mi
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        physicianID
        doctorName
        searchByName
        byName
        doctorProfilePhoto
        doctorDegree
        doctorEmail
        aboutDoctor
        doctorEducation
        doctorOfficeAdd
        areaOfSpeciality
        bodyArea
        specialities
        doctorOfficeCity
        doctorOfficeState
        doctorOfficeZip
        doctorOfficePhone
        doctorOfficeFax
        doctorPracticeName
        doctorWebsite
        doctorFacility
        surgeryCenter
        googleRating
        facebookRating
        testimonial
        activeOnPortal
        distance
        specialty
        location {
          lat
          lon
          __typename
        }
        startDate
        isDpcDoctor
        dpcUsers {
          nextToken
          __typename
        }
        dpcMembers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      total
      nextToken
      __typename
    }
  }
`;
export const nearBySurgeryCenters = /* GraphQL */ `
  query NearBySurgeryCenters(
    $location: LocationInput!
    $surgeryCenterSpeciality: [String]
    $specialty: String
    $mi: Int
    $limit: Int
    $nextToken: String
  ) {
    nearBySurgeryCenters(
      location: $location
      surgeryCenterSpeciality: $surgeryCenterSpeciality
      specialty: $specialty
      mi: $mi
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        surgeryCenterName
        searchByName
        surgeryCenterProfilePhoto
        surgeryPhotos
        surgeryMapSource
        aboutSurgeryCenter
        surgeryCenterAddLine1
        surgeryCenterAddLine2
        surgeryCenterAddLine3
        surgeryCenterZip
        surgeryCenterPhone
        surgeryCenterSpeciality
        specialty
        specailtyAndBodyPart {
          specialty
          surgeryCenterSpeciality
          __typename
        }
        surgeryCenterWebsite
        surgeryCenterPhotos
        surgeryCenterGoogleRating
        surgeryCenterFaceBookRating
        surgeryCenterEmail
        testimonial
        activeOnPortal
        distance
        location {
          lat
          lon
          __typename
        }
        byName
        createdAt
        updatedAt
        __typename
      }
      total
      nextToken
      __typename
    }
  }
`;
export const nearByLabs = /* GraphQL */ `
  query NearByLabs(
    $location: LocationInput!
    $mi: Int
    $limit: Int
    $nextToken: String
  ) {
    nearByLabs(
      location: $location
      mi: $mi
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        labName
        labFacilityID
        labMapSource
        labAppointmentLink
        labServicesLink
        labEmail
        labProfilePhoto
        aboutLab
        labOfficeAdd
        labOfficeCity
        labOfficeState
        labOfficeZip
        labOfficePhone
        labAddLine2
        labWebsite
        labGoogleRating
        labFaceBookRating
        labType
        labPhotos
        searchByName
        testimonial
        activeOnPortal
        occupationalTherapy
        distance
        location {
          lat
          lon
          __typename
        }
        byName
        createdAt
        updatedAt
        __typename
      }
      total
      nextToken
      __typename
    }
  }
`;
export const nearByPhysicalTherapies = /* GraphQL */ `
  query NearByPhysicalTherapies(
    $location: LocationInput!
    $mi: Int
    $limit: Int
    $nextToken: String
  ) {
    nearByPhysicalTherapies(
      location: $location
      mi: $mi
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ptFacilityID
        ptName
        ptMapSource
        aboutPT
        ptAddLine1
        ptAddLine2
        ptAddLine3
        ptZipCode
        ptPhone
        ptFax
        ptProfilePhoto
        ptEmail
        ptWebsite
        ptGoogleRating
        ptFaceBookRating
        ptPhotos
        ptType
        ptOccupationalTherapy
        searchByName
        testimonial
        activeOnPortal
        distance
        location {
          lat
          lon
          __typename
        }
        byName
        createdAt
        updatedAt
        __typename
      }
      total
      nextToken
      __typename
    }
  }
`;
export const nearByImagingCenters = /* GraphQL */ `
  query NearByImagingCenters(
    $location: LocationInput!
    $icSpecialities: String
    $mi: Int
    $limit: Int
    $nextToken: String
  ) {
    nearByImagingCenters(
      location: $location
      icSpecialities: $icSpecialities
      mi: $mi
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        icPractiseName
        icMapSource
        icAddLine2
        icAppointmentLink
        icID
        icEmail
        icSpecialities
        aboutIC
        icOfficeAdd
        icOfficeCity
        icOfficeState
        icOfficeZip
        icOfficePhone
        icOfficeFax
        icWebsite
        icFacility
        icOfficeName
        icGoogleRating
        icFaceBookRating
        icProfilePhoto
        icPhotos
        searchByName
        testimonial
        activeOnPortal
        distance
        location {
          lat
          lon
          __typename
        }
        byName
        createdAt
        updatedAt
        __typename
      }
      total
      nextToken
      __typename
    }
  }
`;
export const nearByInfusionCenters = /* GraphQL */ `
  query NearByInfusionCenters(
    $location: LocationInput!
    $mi: Int
    $limit: Int
    $nextToken: String
  ) {
    nearByInfusionCenters(
      location: $location
      mi: $mi
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        profilePhoto
        name
        infusionDrugs
        about
        email
        website
        phone
        fax
        streetAddress
        city
        state
        zip
        googleRating
        infusionCenterPhotos
        facebookRating
        googleMapLink
        searchByName
        testimonial
        activeOnPortal
        distance
        location {
          lat
          lon
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      total
      nextToken
      __typename
    }
  }
`;
export const globalSearch = /* GraphQL */ `
  query GlobalSearch(
    $location: LocationInput!
    $mi: Int
    $limit: Int
    $nextToken: Int
    $query: String
    $index: String
    $size: Int
  ) {
    globalSearch(
      location: $location
      mi: $mi
      limit: $limit
      nextToken: $nextToken
      query: $query
      index: $index
      size: $size
    ) {
      doctors {
        data {
          id
          physicianID
          doctorName
          searchByName
          byName
          doctorProfilePhoto
          doctorDegree
          doctorEmail
          aboutDoctor
          doctorEducation
          doctorOfficeAdd
          areaOfSpeciality
          bodyArea
          specialities
          doctorOfficeCity
          doctorOfficeState
          doctorOfficeZip
          doctorOfficePhone
          doctorOfficeFax
          doctorPracticeName
          doctorWebsite
          doctorFacility
          surgeryCenter
          googleRating
          facebookRating
          testimonial
          activeOnPortal
          distance
          specialty
          startDate
          isDpcDoctor
          createdAt
          updatedAt
          __typename
        }
        total
        __typename
      }
      surgeryCenters {
        data {
          id
          surgeryCenterName
          searchByName
          surgeryCenterProfilePhoto
          surgeryPhotos
          surgeryMapSource
          aboutSurgeryCenter
          surgeryCenterAddLine1
          surgeryCenterAddLine2
          surgeryCenterAddLine3
          surgeryCenterZip
          surgeryCenterPhone
          surgeryCenterSpeciality
          specialty
          surgeryCenterWebsite
          surgeryCenterPhotos
          surgeryCenterGoogleRating
          surgeryCenterFaceBookRating
          surgeryCenterEmail
          testimonial
          activeOnPortal
          distance
          byName
          createdAt
          updatedAt
          __typename
        }
        total
        __typename
      }
      imgagingCenters {
        data {
          id
          icPractiseName
          icMapSource
          icAddLine2
          icAppointmentLink
          icID
          icEmail
          icSpecialities
          aboutIC
          icOfficeAdd
          icOfficeCity
          icOfficeState
          icOfficeZip
          icOfficePhone
          icOfficeFax
          icWebsite
          icFacility
          icOfficeName
          icGoogleRating
          icFaceBookRating
          icProfilePhoto
          icPhotos
          searchByName
          testimonial
          activeOnPortal
          distance
          byName
          createdAt
          updatedAt
          __typename
        }
        total
        __typename
      }
      ptCenters {
        data {
          id
          ptFacilityID
          ptName
          ptMapSource
          aboutPT
          ptAddLine1
          ptAddLine2
          ptAddLine3
          ptZipCode
          ptPhone
          ptFax
          ptProfilePhoto
          ptEmail
          ptWebsite
          ptGoogleRating
          ptFaceBookRating
          ptPhotos
          ptType
          ptOccupationalTherapy
          searchByName
          testimonial
          activeOnPortal
          distance
          byName
          createdAt
          updatedAt
          __typename
        }
        total
        __typename
      }
      labs {
        data {
          id
          labName
          labFacilityID
          labMapSource
          labAppointmentLink
          labServicesLink
          labEmail
          labProfilePhoto
          aboutLab
          labOfficeAdd
          labOfficeCity
          labOfficeState
          labOfficeZip
          labOfficePhone
          labAddLine2
          labWebsite
          labGoogleRating
          labFaceBookRating
          labType
          labPhotos
          searchByName
          testimonial
          activeOnPortal
          occupationalTherapy
          distance
          byName
          createdAt
          updatedAt
          __typename
        }
        total
        __typename
      }
      procedures {
        data {
          id
          name
          searchByName
          savings
          bazarPrice
          marketPrice
          reward
          bodyPart
          focusOnBody
          about
          description
          faq
          belongsToService
          image
          activeOnPortal
          byName
          createdAt
          updatedAt
          __typename
        }
        total
        __typename
      }
      infusionCenters {
        data {
          id
          profilePhoto
          name
          infusionDrugs
          about
          email
          website
          phone
          fax
          streetAddress
          city
          state
          zip
          googleRating
          infusionCenterPhotos
          facebookRating
          googleMapLink
          searchByName
          testimonial
          activeOnPortal
          distance
          createdAt
          updatedAt
          __typename
        }
        total
        __typename
      }
      __typename
    }
  }
`;
export const getDoctorProfile = /* GraphQL */ `
  query GetDoctorProfile($id: ID!) {
    getDoctorProfile(id: $id) {
      id
      physicianID
      doctorName
      searchByName
      byName
      doctorProfilePhoto
      doctorDegree
      doctorEmail
      aboutDoctor
      doctorEducation
      doctorOfficeAdd
      areaOfSpeciality
      bodyArea
      specialities
      doctorOfficeCity
      doctorOfficeState
      doctorOfficeZip
      doctorOfficePhone
      doctorOfficeFax
      doctorPracticeName
      doctorWebsite
      doctorFacility
      surgeryCenter
      googleRating
      facebookRating
      testimonial
      activeOnPortal
      distance
      specialty
      location {
        lat
        lon
        __typename
      }
      startDate
      isDpcDoctor
      dpcUsers {
        items {
          id
          firstName
          lastName
          fcmToken
          searchByName
          employeeEmail
          emailType
          verified
          role
          userType
          emailID
          inList
          lastLogin
          billing_status
          total_user
          promotionalID
          dpcDoctorID
          DpcBillingID
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          companyRegiEmployeeId
          employeeRegiUnSubscribeIDId
          owner
          __typename
        }
        nextToken
        __typename
      }
      dpcMembers {
        items {
          id
          firstName
          lastName
          fcmToken
          searchByName
          lastLogin
          relation
          group
          email
          emailType
          verified
          role
          userType
          inList
          emailID
          DpcBillingID
          dpcEmployeeID
          dpcDoctorID
          billing_status
          accountStatus
          stripeSubscriptionID
          createdAt
          updatedAt
          employeeRegiMembersId
          companyRegiMemberId
          memberUnSubscribeIDId
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDoctorProfiles = /* GraphQL */ `
  query ListDoctorProfiles(
    $filter: ModelDoctorProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDoctorProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        physicianID
        doctorName
        searchByName
        byName
        doctorProfilePhoto
        doctorDegree
        doctorEmail
        aboutDoctor
        doctorEducation
        doctorOfficeAdd
        areaOfSpeciality
        bodyArea
        specialities
        doctorOfficeCity
        doctorOfficeState
        doctorOfficeZip
        doctorOfficePhone
        doctorOfficeFax
        doctorPracticeName
        doctorWebsite
        doctorFacility
        surgeryCenter
        googleRating
        facebookRating
        testimonial
        activeOnPortal
        distance
        specialty
        location {
          lat
          lon
          __typename
        }
        startDate
        isDpcDoctor
        dpcUsers(limit: 99999) {
          items {
            id
          }
          nextToken
          __typename
        }
        dpcMembers(limit: 99999) {
          items {
            id
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const doctorByName = /* GraphQL */ `
  query DoctorByName(
    $byName: String!
    $searchByName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModeldoctorProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    doctorByName(
      byName: $byName
      searchByName: $searchByName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        physicianID
        doctorName
        searchByName
        byName
        doctorProfilePhoto
        doctorDegree
        doctorEmail
        aboutDoctor
        doctorEducation
        doctorOfficeAdd
        areaOfSpeciality
        bodyArea
        specialities
        doctorOfficeCity
        doctorOfficeState
        doctorOfficeZip
        doctorOfficePhone
        doctorOfficeFax
        doctorPracticeName
        doctorWebsite
        doctorFacility
        surgeryCenter
        googleRating
        facebookRating
        testimonial
        activeOnPortal
        distance
        specialty
        location {
          lat
          lon
          __typename
        }
        startDate
        isDpcDoctor
        dpcUsers {
          nextToken
          __typename
        }
        dpcMembers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchDoctorProfiles = /* GraphQL */ `
  query SearchDoctorProfiles(
    $filter: SearchabledoctorProfileFilterInput
    $sort: [SearchabledoctorProfileSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchabledoctorProfileAggregationInput]
  ) {
    searchDoctorProfiles(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        physicianID
        doctorName
        searchByName
        byName
        doctorProfilePhoto
        doctorDegree
        doctorEmail
        aboutDoctor
        doctorEducation
        doctorOfficeAdd
        areaOfSpeciality
        bodyArea
        specialities
        doctorOfficeCity
        doctorOfficeState
        doctorOfficeZip
        doctorOfficePhone
        doctorOfficeFax
        doctorPracticeName
        doctorWebsite
        doctorFacility
        surgeryCenter
        googleRating
        facebookRating
        testimonial
        activeOnPortal
        distance
        specialty
        location {
          lat
          lon
          __typename
        }
        startDate
        isDpcDoctor
        dpcUsers {
          nextToken
          __typename
        }
        dpcMembers {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getSurgeryCenterDetails = /* GraphQL */ `
  query GetSurgeryCenterDetails($id: ID!) {
    getSurgeryCenterDetails(id: $id) {
      id
      surgeryCenterName
      searchByName
      surgeryCenterProfilePhoto
      surgeryPhotos
      surgeryMapSource
      aboutSurgeryCenter
      surgeryCenterAddLine1
      surgeryCenterAddLine2
      surgeryCenterAddLine3
      surgeryCenterZip
      surgeryCenterPhone
      surgeryCenterSpeciality
      specialty
      specailtyAndBodyPart {
        specialty
        surgeryCenterSpeciality
        __typename
      }
      surgeryCenterWebsite
      surgeryCenterPhotos
      surgeryCenterGoogleRating
      surgeryCenterFaceBookRating
      surgeryCenterEmail
      testimonial
      activeOnPortal
      distance
      location {
        lat
        lon
        __typename
      }
      byName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSurgeryCenterDetails = /* GraphQL */ `
  query ListSurgeryCenterDetails(
    $filter: ModelSurgeryCenterDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurgeryCenterDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        surgeryCenterName
        searchByName
        surgeryCenterProfilePhoto
        surgeryPhotos
        surgeryMapSource
        aboutSurgeryCenter
        surgeryCenterAddLine1
        surgeryCenterAddLine2
        surgeryCenterAddLine3
        surgeryCenterZip
        surgeryCenterPhone
        surgeryCenterSpeciality
        specialty
        specailtyAndBodyPart {
          specialty
          surgeryCenterSpeciality
          __typename
        }
        surgeryCenterWebsite
        surgeryCenterPhotos
        surgeryCenterGoogleRating
        surgeryCenterFaceBookRating
        surgeryCenterEmail
        testimonial
        activeOnPortal
        distance
        location {
          lat
          lon
          __typename
        }
        byName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const surgeryCenterByName = /* GraphQL */ `
  query SurgeryCenterByName(
    $byName: String!
    $searchByName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelsurgeryCenterDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    surgeryCenterByName(
      byName: $byName
      searchByName: $searchByName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        surgeryCenterName
        searchByName
        surgeryCenterProfilePhoto
        surgeryPhotos
        surgeryMapSource
        aboutSurgeryCenter
        surgeryCenterAddLine1
        surgeryCenterAddLine2
        surgeryCenterAddLine3
        surgeryCenterZip
        surgeryCenterPhone
        surgeryCenterSpeciality
        specialty
        specailtyAndBodyPart {
          specialty
          surgeryCenterSpeciality
          __typename
        }
        surgeryCenterWebsite
        surgeryCenterPhotos
        surgeryCenterGoogleRating
        surgeryCenterFaceBookRating
        surgeryCenterEmail
        testimonial
        activeOnPortal
        distance
        location {
          lat
          lon
          __typename
        }
        byName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchSurgeryCenterDetails = /* GraphQL */ `
  query SearchSurgeryCenterDetails(
    $filter: SearchablesurgeryCenterDetailsFilterInput
    $sort: [SearchablesurgeryCenterDetailsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablesurgeryCenterDetailsAggregationInput]
  ) {
    searchSurgeryCenterDetails(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        surgeryCenterName
        searchByName
        surgeryCenterProfilePhoto
        surgeryPhotos
        surgeryMapSource
        aboutSurgeryCenter
        surgeryCenterAddLine1
        surgeryCenterAddLine2
        surgeryCenterAddLine3
        surgeryCenterZip
        surgeryCenterPhone
        surgeryCenterSpeciality
        specialty
        specailtyAndBodyPart {
          specialty
          surgeryCenterSpeciality
          __typename
        }
        surgeryCenterWebsite
        surgeryCenterPhotos
        surgeryCenterGoogleRating
        surgeryCenterFaceBookRating
        surgeryCenterEmail
        testimonial
        activeOnPortal
        distance
        location {
          lat
          lon
          __typename
        }
        byName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getLabDetails = /* GraphQL */ `
  query GetLabDetails($id: ID!) {
    getLabDetails(id: $id) {
      id
      labName
      labFacilityID
      labMapSource
      labAppointmentLink
      labServicesLink
      labEmail
      labProfilePhoto
      aboutLab
      labOfficeAdd
      labOfficeCity
      labOfficeState
      labOfficeZip
      labOfficePhone
      labAddLine2
      labWebsite
      labGoogleRating
      labFaceBookRating
      labType
      labPhotos
      searchByName
      testimonial
      activeOnPortal
      occupationalTherapy
      distance
      location {
        lat
        lon
        __typename
      }
      byName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLabDetails = /* GraphQL */ `
  query ListLabDetails(
    $filter: ModelLabDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLabDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        labName
        labFacilityID
        labMapSource
        labAppointmentLink
        labServicesLink
        labEmail
        labProfilePhoto
        aboutLab
        labOfficeAdd
        labOfficeCity
        labOfficeState
        labOfficeZip
        labOfficePhone
        labAddLine2
        labWebsite
        labGoogleRating
        labFaceBookRating
        labType
        labPhotos
        searchByName
        testimonial
        activeOnPortal
        occupationalTherapy
        distance
        location {
          lat
          lon
          __typename
        }
        byName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const labByName = /* GraphQL */ `
  query LabByName(
    $byName: String!
    $searchByName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModellabDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    labByName(
      byName: $byName
      searchByName: $searchByName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        labName
        labFacilityID
        labMapSource
        labAppointmentLink
        labServicesLink
        labEmail
        labProfilePhoto
        aboutLab
        labOfficeAdd
        labOfficeCity
        labOfficeState
        labOfficeZip
        labOfficePhone
        labAddLine2
        labWebsite
        labGoogleRating
        labFaceBookRating
        labType
        labPhotos
        searchByName
        testimonial
        activeOnPortal
        occupationalTherapy
        distance
        location {
          lat
          lon
          __typename
        }
        byName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchLabDetails = /* GraphQL */ `
  query SearchLabDetails(
    $filter: SearchablelabDetailsFilterInput
    $sort: [SearchablelabDetailsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablelabDetailsAggregationInput]
  ) {
    searchLabDetails(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        labName
        labFacilityID
        labMapSource
        labAppointmentLink
        labServicesLink
        labEmail
        labProfilePhoto
        aboutLab
        labOfficeAdd
        labOfficeCity
        labOfficeState
        labOfficeZip
        labOfficePhone
        labAddLine2
        labWebsite
        labGoogleRating
        labFaceBookRating
        labType
        labPhotos
        searchByName
        testimonial
        activeOnPortal
        occupationalTherapy
        distance
        location {
          lat
          lon
          __typename
        }
        byName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getInfusionCenter = /* GraphQL */ `
  query GetInfusionCenter($id: ID!) {
    getInfusionCenter(id: $id) {
      id
      profilePhoto
      name
      infusionDrugs
      about
      email
      website
      phone
      fax
      streetAddress
      city
      state
      zip
      googleRating
      infusionCenterPhotos
      facebookRating
      googleMapLink
      searchByName
      testimonial
      activeOnPortal
      distance
      location {
        lat
        lon
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listInfusionCenters = /* GraphQL */ `
  query ListInfusionCenters(
    $filter: ModelInfusionCenterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInfusionCenters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        profilePhoto
        name
        infusionDrugs
        about
        email
        website
        phone
        fax
        streetAddress
        city
        state
        zip
        googleRating
        infusionCenterPhotos
        facebookRating
        googleMapLink
        searchByName
        testimonial
        activeOnPortal
        distance
        location {
          lat
          lon
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchInfusionCenters = /* GraphQL */ `
  query SearchInfusionCenters(
    $filter: SearchableinfusionCenterFilterInput
    $sort: [SearchableinfusionCenterSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableinfusionCenterAggregationInput]
  ) {
    searchInfusionCenters(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        profilePhoto
        name
        infusionDrugs
        about
        email
        website
        phone
        fax
        streetAddress
        city
        state
        zip
        googleRating
        infusionCenterPhotos
        facebookRating
        googleMapLink
        searchByName
        testimonial
        activeOnPortal
        distance
        location {
          lat
          lon
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getPhysicalTherapyDetails = /* GraphQL */ `
  query GetPhysicalTherapyDetails($id: ID!) {
    getPhysicalTherapyDetails(id: $id) {
      id
      ptFacilityID
      ptName
      ptMapSource
      aboutPT
      ptAddLine1
      ptAddLine2
      ptAddLine3
      ptZipCode
      ptPhone
      ptFax
      ptProfilePhoto
      ptEmail
      ptWebsite
      ptGoogleRating
      ptFaceBookRating
      ptPhotos
      ptType
      ptOccupationalTherapy
      searchByName
      testimonial
      activeOnPortal
      distance
      location {
        lat
        lon
        __typename
      }
      byName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPhysicalTherapyDetails = /* GraphQL */ `
  query ListPhysicalTherapyDetails(
    $filter: ModelPhysicalTherapyDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhysicalTherapyDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ptFacilityID
        ptName
        ptMapSource
        aboutPT
        ptAddLine1
        ptAddLine2
        ptAddLine3
        ptZipCode
        ptPhone
        ptFax
        ptProfilePhoto
        ptEmail
        ptWebsite
        ptGoogleRating
        ptFaceBookRating
        ptPhotos
        ptType
        ptOccupationalTherapy
        searchByName
        testimonial
        activeOnPortal
        distance
        location {
          lat
          lon
          __typename
        }
        byName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ptByName = /* GraphQL */ `
  query PtByName(
    $byName: String!
    $searchByName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelphysicalTherapyDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ptByName(
      byName: $byName
      searchByName: $searchByName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ptFacilityID
        ptName
        ptMapSource
        aboutPT
        ptAddLine1
        ptAddLine2
        ptAddLine3
        ptZipCode
        ptPhone
        ptFax
        ptProfilePhoto
        ptEmail
        ptWebsite
        ptGoogleRating
        ptFaceBookRating
        ptPhotos
        ptType
        ptOccupationalTherapy
        searchByName
        testimonial
        activeOnPortal
        distance
        location {
          lat
          lon
          __typename
        }
        byName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchPhysicalTherapyDetails = /* GraphQL */ `
  query SearchPhysicalTherapyDetails(
    $filter: SearchablephysicalTherapyDetailsFilterInput
    $sort: [SearchablephysicalTherapyDetailsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablephysicalTherapyDetailsAggregationInput]
  ) {
    searchPhysicalTherapyDetails(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        ptFacilityID
        ptName
        ptMapSource
        aboutPT
        ptAddLine1
        ptAddLine2
        ptAddLine3
        ptZipCode
        ptPhone
        ptFax
        ptProfilePhoto
        ptEmail
        ptWebsite
        ptGoogleRating
        ptFaceBookRating
        ptPhotos
        ptType
        ptOccupationalTherapy
        searchByName
        testimonial
        activeOnPortal
        distance
        location {
          lat
          lon
          __typename
        }
        byName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getImgagingCenterDetails = /* GraphQL */ `
  query GetImgagingCenterDetails($id: ID!) {
    getImgagingCenterDetails(id: $id) {
      id
      icPractiseName
      icMapSource
      icAddLine2
      icAppointmentLink
      icID
      icEmail
      icSpecialities
      aboutIC
      icOfficeAdd
      icOfficeCity
      icOfficeState
      icOfficeZip
      icOfficePhone
      icOfficeFax
      icWebsite
      icFacility
      icOfficeName
      icGoogleRating
      icFaceBookRating
      icProfilePhoto
      icPhotos
      searchByName
      testimonial
      activeOnPortal
      distance
      location {
        lat
        lon
        __typename
      }
      byName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listImgagingCenterDetails = /* GraphQL */ `
  query ListImgagingCenterDetails(
    $filter: ModelImgagingCenterDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImgagingCenterDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        icPractiseName
        icMapSource
        icAddLine2
        icAppointmentLink
        icID
        icEmail
        icSpecialities
        aboutIC
        icOfficeAdd
        icOfficeCity
        icOfficeState
        icOfficeZip
        icOfficePhone
        icOfficeFax
        icWebsite
        icFacility
        icOfficeName
        icGoogleRating
        icFaceBookRating
        icProfilePhoto
        icPhotos
        searchByName
        testimonial
        activeOnPortal
        distance
        location {
          lat
          lon
          __typename
        }
        byName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const icByName = /* GraphQL */ `
  query IcByName(
    $byName: String!
    $searchByName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelimgagingCenterDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    icByName(
      byName: $byName
      searchByName: $searchByName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        icPractiseName
        icMapSource
        icAddLine2
        icAppointmentLink
        icID
        icEmail
        icSpecialities
        aboutIC
        icOfficeAdd
        icOfficeCity
        icOfficeState
        icOfficeZip
        icOfficePhone
        icOfficeFax
        icWebsite
        icFacility
        icOfficeName
        icGoogleRating
        icFaceBookRating
        icProfilePhoto
        icPhotos
        searchByName
        testimonial
        activeOnPortal
        distance
        location {
          lat
          lon
          __typename
        }
        byName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchImgagingCenterDetails = /* GraphQL */ `
  query SearchImgagingCenterDetails(
    $filter: SearchableimgagingCenterDetailsFilterInput
    $sort: [SearchableimgagingCenterDetailsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableimgagingCenterDetailsAggregationInput]
  ) {
    searchImgagingCenterDetails(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        icPractiseName
        icMapSource
        icAddLine2
        icAppointmentLink
        icID
        icEmail
        icSpecialities
        aboutIC
        icOfficeAdd
        icOfficeCity
        icOfficeState
        icOfficeZip
        icOfficePhone
        icOfficeFax
        icWebsite
        icFacility
        icOfficeName
        icGoogleRating
        icFaceBookRating
        icProfilePhoto
        icPhotos
        searchByName
        testimonial
        activeOnPortal
        distance
        location {
          lat
          lon
          __typename
        }
        byName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getSuggestService = /* GraphQL */ `
  query GetSuggestService($id: ID!) {
    getSuggestService(id: $id) {
      id
      customerID
      serviceType
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSuggestServices = /* GraphQL */ `
  query ListSuggestServices(
    $filter: ModelSuggestServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSuggestServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerID
        serviceType
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFaq = /* GraphQL */ `
  query GetFaq($id: ID!) {
    getFaq(id: $id) {
      id
      faqType
      faqQuestion
      faqAnswer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFaqs = /* GraphQL */ `
  query ListFaqs(
    $filter: ModelFaqFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFaqs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        faqType
        faqQuestion
        faqAnswer
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDoctorAppointment = /* GraphQL */ `
  query GetDoctorAppointment($id: ID!) {
    getDoctorAppointment(id: $id) {
      id
      userData {
        id
        name
        appointmentFor
        preferredDay
        otherName
        preferredWeekDay
        preferredTime
        memberID
        insuranceCarrier
        relationship
        email
        phone
        dob
        otherDob
        __typename
      }
      userID
      docData {
        id
        name
        email
        phone
        fax
        speciality
        __typename
      }
      messageID
      faxID
      faxDetails {
        to
        message
        faxID
        sentDateTime
        faxStatus
        statusReason
        statusDateTime
        __typename
      }
      emailDetails {
        to
        cc
        emailuuID
        mailRecieved
        subject
        message
        messageID
        sentDateTime
        emailOpenTime
        __typename
      }
      description
      openToDifferentProvider
      status
      viewed
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDoctorAppointments = /* GraphQL */ `
  query ListDoctorAppointments(
    $filter: ModelDoctorAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDoctorAppointments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userData {
          id
          name
          appointmentFor
          preferredDay
          otherName
          preferredWeekDay
          preferredTime
          memberID
          insuranceCarrier
          relationship
          email
          phone
          dob
          otherDob
          __typename
        }
        userID
        docData {
          id
          name
          email
          phone
          fax
          speciality
          __typename
        }
        messageID
        faxID
        faxDetails {
          to
          message
          faxID
          sentDateTime
          faxStatus
          statusReason
          statusDateTime
          __typename
        }
        emailDetails {
          to
          cc
          emailuuID
          mailRecieved
          subject
          message
          messageID
          sentDateTime
          emailOpenTime
          __typename
        }
        description
        openToDifferentProvider
        status
        viewed
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getIcAppointment = /* GraphQL */ `
  query GetIcAppointment($id: ID!) {
    getIcAppointment(id: $id) {
      id
      userData {
        id
        name
        appointmentFor
        preferredDay
        otherName
        preferredWeekDay
        preferredTime
        memberID
        insuranceCarrier
        relationship
        email
        phone
        dob
        otherDob
        __typename
      }
      userID
      icData {
        id
        name
        email
        phone
        fax
        speciality
        __typename
      }
      messageID
      faxID
      faxDetails {
        to
        message
        faxID
        sentDateTime
        faxStatus
        statusReason
        statusDateTime
        __typename
      }
      emailDetails {
        to
        cc
        emailuuID
        mailRecieved
        subject
        message
        messageID
        sentDateTime
        emailOpenTime
        __typename
      }
      bodyPartToBeScanned
      description
      referralOfDoctor
      doctorName
      firstVisit
      followUpAppointment
      status
      viewed
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listIcAppointments = /* GraphQL */ `
  query ListIcAppointments(
    $filter: ModelIcAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIcAppointments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userData {
          id
          name
          appointmentFor
          preferredDay
          otherName
          preferredWeekDay
          preferredTime
          memberID
          insuranceCarrier
          relationship
          email
          phone
          dob
          otherDob
          __typename
        }
        userID
        icData {
          id
          name
          email
          phone
          fax
          speciality
          __typename
        }
        messageID
        faxID
        faxDetails {
          to
          message
          faxID
          sentDateTime
          faxStatus
          statusReason
          statusDateTime
          __typename
        }
        emailDetails {
          to
          cc
          emailuuID
          mailRecieved
          subject
          message
          messageID
          sentDateTime
          emailOpenTime
          __typename
        }
        bodyPartToBeScanned
        description
        referralOfDoctor
        doctorName
        firstVisit
        followUpAppointment
        status
        viewed
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPtAppointment = /* GraphQL */ `
  query GetPtAppointment($id: ID!) {
    getPtAppointment(id: $id) {
      id
      userData {
        id
        name
        appointmentFor
        preferredDay
        otherName
        preferredWeekDay
        preferredTime
        memberID
        insuranceCarrier
        relationship
        email
        phone
        dob
        otherDob
        __typename
      }
      userID
      ptData {
        id
        name
        email
        phone
        fax
        __typename
      }
      messageID
      faxID
      faxDetails {
        to
        message
        faxID
        sentDateTime
        faxStatus
        statusReason
        statusDateTime
        __typename
      }
      emailDetails {
        to
        cc
        emailuuID
        mailRecieved
        subject
        message
        messageID
        sentDateTime
        emailOpenTime
        __typename
      }
      openToOther
      comment
      haveReferral
      status
      preferredContact
      viewed
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPtAppointments = /* GraphQL */ `
  query ListPtAppointments(
    $filter: ModelPtAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPtAppointments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userData {
          id
          name
          appointmentFor
          preferredDay
          otherName
          preferredWeekDay
          preferredTime
          memberID
          insuranceCarrier
          relationship
          email
          phone
          dob
          otherDob
          __typename
        }
        userID
        ptData {
          id
          name
          email
          phone
          fax
          __typename
        }
        messageID
        faxID
        faxDetails {
          to
          message
          faxID
          sentDateTime
          faxStatus
          statusReason
          statusDateTime
          __typename
        }
        emailDetails {
          to
          cc
          emailuuID
          mailRecieved
          subject
          message
          messageID
          sentDateTime
          emailOpenTime
          __typename
        }
        openToOther
        comment
        haveReferral
        status
        preferredContact
        viewed
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInfusionAppointment = /* GraphQL */ `
  query GetInfusionAppointment($id: ID!) {
    getInfusionAppointment(id: $id) {
      id
      userData {
        id
        name
        appointmentFor
        preferredDay
        otherName
        preferredWeekDay
        preferredTime
        memberID
        insuranceCarrier
        relationship
        email
        phone
        dob
        otherDob
        __typename
      }
      userID
      infusionCenterData {
        id
        name
        email
        phone
        fax
        __typename
      }
      messageID
      faxID
      faxDetails {
        to
        message
        faxID
        sentDateTime
        faxStatus
        statusReason
        statusDateTime
        __typename
      }
      emailDetails {
        to
        cc
        emailuuID
        mailRecieved
        subject
        message
        messageID
        sentDateTime
        emailOpenTime
        __typename
      }
      status
      viewed
      referringProvider
      providerPracticeName
      providerPhoneNumber
      drugBeingInfused
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listInfusionAppointments = /* GraphQL */ `
  query ListInfusionAppointments(
    $filter: ModelInfusionAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInfusionAppointments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userData {
          id
          name
          appointmentFor
          preferredDay
          otherName
          preferredWeekDay
          preferredTime
          memberID
          insuranceCarrier
          relationship
          email
          phone
          dob
          otherDob
          __typename
        }
        userID
        infusionCenterData {
          id
          name
          email
          phone
          fax
          __typename
        }
        messageID
        faxID
        faxDetails {
          to
          message
          faxID
          sentDateTime
          faxStatus
          statusReason
          statusDateTime
          __typename
        }
        emailDetails {
          to
          cc
          emailuuID
          mailRecieved
          subject
          message
          messageID
          sentDateTime
          emailOpenTime
          __typename
        }
        status
        viewed
        referringProvider
        providerPracticeName
        providerPhoneNumber
        drugBeingInfused
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTestimonials = /* GraphQL */ `
  query GetTestimonials($id: ID!) {
    getTestimonials(id: $id) {
      id
      centerID
      testimonial
      by
      rating
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTestimonials = /* GraphQL */ `
  query ListTestimonials(
    $filter: ModelTestimonialsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestimonials(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        centerID
        testimonial
        by
        rating
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRewardSubmissions = /* GraphQL */ `
  query GetRewardSubmissions($id: ID!) {
    getRewardSubmissions(id: $id) {
      id
      customerID
      customerEmail
      rewardFor
      name
      dob
      typeOfCare
      nameOfProcedure
      claimNumber
      status
      uploadedImages
      approvedBy
      rewardAmount
      approvedDate
      dateSubmitted
      searchByName
      searchByProcedure
      feedback {
        rating
        comment
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRewardSubmissions = /* GraphQL */ `
  query ListRewardSubmissions(
    $filter: ModelRewardSubmissionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRewardSubmissions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        customerEmail
        rewardFor
        name
        dob
        typeOfCare
        nameOfProcedure
        claimNumber
        status
        uploadedImages
        approvedBy
        rewardAmount
        approvedDate
        dateSubmitted
        searchByName
        searchByProcedure
        feedback {
          rating
          comment
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmployeeRegi = /* GraphQL */ `
  query GetEmployeeRegi($id: ID!) {
    getEmployeeRegi(id: $id) {
      id
      firstName
      lastName
      fcmToken
      searchByName
      employeeEmail
      emailType
      verified
      role
      userType
      emailID
      inList
      lastLogin
      billing_status
      total_user
      members {
        items {
          id
          firstName
          lastName
          fcmToken
          searchByName
          lastLogin
          relation
          group
          email
          emailType
          verified
          role
          userType
          inList
          emailID
          DpcBillingID
          dpcEmployeeID
          dpcDoctorID
          billing_status
          accountStatus
          stripeSubscriptionID
          createdAt
          updatedAt
          employeeRegiMembersId
          companyRegiMemberId
          memberUnSubscribeIDId
          owner
          __typename
        }
        nextToken
        __typename
      }
      dpcMembers {
        items {
          id
          firstName
          lastName
          fcmToken
          searchByName
          lastLogin
          relation
          group
          email
          emailType
          verified
          role
          userType
          inList
          emailID
          DpcBillingID
          dpcEmployeeID
          dpcDoctorID
          billing_status
          accountStatus
          stripeSubscriptionID
          createdAt
          updatedAt
          employeeRegiMembersId
          companyRegiMemberId
          memberUnSubscribeIDId
          owner
          __typename
        }
        nextToken
        __typename
      }
      redeemedCoupon {
        promotionalID
        date
        __typename
      }
      promotionalID
      dpcDoctorID
      companyID {
        id
        approved
        name
        searchByName
        url
        phoneNumber
        email
        emailDomain
        planCategory
        customPlanDetails {
          price
          maxEmployess
          name
          __typename
        }
        profileID
        profile {
          email
          name
          url
          phoneNumber
          benefitInfoCall
          adminDetails
          companyID
          streetAddress
          zip
          city
          state
          benefitDocs
          visionDocs
          otherDocs
          createdAt
          updatedAt
          owner
          __typename
        }
        unSubscribeID {
          id
          reason
          createdAt
          updatedAt
          unSubscribeEmployeeId
          unSubscribeMemberId
          unSubscribeCompanyId
          owner
          __typename
        }
        employee {
          nextToken
          __typename
        }
        member {
          nextToken
          __typename
        }
        billing_status
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        plan {
          id
          name
          planDetails
          createdAt
          updatedAt
          __typename
        }
        metadata
        createdAt
        updatedAt
        companyRegiUnSubscribeIDId
        owner
        __typename
      }
      unSubscribeID {
        id
        reason
        employee {
          id
          firstName
          lastName
          fcmToken
          searchByName
          employeeEmail
          emailType
          verified
          role
          userType
          emailID
          inList
          lastLogin
          billing_status
          total_user
          promotionalID
          dpcDoctorID
          DpcBillingID
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          companyRegiEmployeeId
          employeeRegiUnSubscribeIDId
          owner
          __typename
        }
        member {
          id
          firstName
          lastName
          fcmToken
          searchByName
          lastLogin
          relation
          group
          email
          emailType
          verified
          role
          userType
          inList
          emailID
          DpcBillingID
          dpcEmployeeID
          dpcDoctorID
          billing_status
          accountStatus
          stripeSubscriptionID
          createdAt
          updatedAt
          employeeRegiMembersId
          companyRegiMemberId
          memberUnSubscribeIDId
          owner
          __typename
        }
        company {
          id
          approved
          name
          searchByName
          url
          phoneNumber
          email
          emailDomain
          planCategory
          profileID
          billing_status
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          companyRegiUnSubscribeIDId
          owner
          __typename
        }
        createdAt
        updatedAt
        unSubscribeEmployeeId
        unSubscribeMemberId
        unSubscribeCompanyId
        owner
        __typename
      }
      DpcBillingID
      DpcDetails {
        id
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        plan {
          id
          name
          planDetails
          createdAt
          updatedAt
          __typename
        }
        metadata
        createdAt
        updatedAt
        __typename
      }
      epcCheckoutLink {
        checkoutID
        url
        expiry
        __typename
      }
      profile {
        email
        profilePhoto
        profileCompleted
        firstName
        lastName
        searchByName
        dob
        insuranceCarrier
        planID
        editStatus
        editRequested
        memberID
        firstVisit {
          reward
          procedure
          homePage
          firstVisit
          doctor
          surgery
          imaging
          infusion
          lab
          physicalTherapy
          __typename
        }
        streetAdress
        city
        state
        zip
        phone
        gender
        location {
          lat
          lon
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      plan {
        id
        name
        planDetails
        createdAt
        updatedAt
        __typename
      }
      metadata
      createdAt
      updatedAt
      companyRegiEmployeeId
      employeeRegiUnSubscribeIDId
      owner
      __typename
    }
  }
`;
export const listEmployeeRegis = /* GraphQL */ `
  query ListEmployeeRegis(
    $filter: ModelEmployeeRegiFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployeeRegis(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        inList
        lastLogin
        billing_status
        total_user
        members {
          nextToken
          __typename
        }
        dpcMembers {
          nextToken
          __typename
        }
        redeemedCoupon {
          promotionalID
          date
          __typename
        }
        promotionalID
        dpcDoctorID
        companyID {
          id
          approved
          name
          searchByName
          url
          phoneNumber
          email
          emailDomain
          planCategory
          profileID
          billing_status
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          companyRegiUnSubscribeIDId
          owner
          __typename
        }
        unSubscribeID {
          id
          reason
          createdAt
          updatedAt
          unSubscribeEmployeeId
          unSubscribeMemberId
          unSubscribeCompanyId
          owner
          __typename
        }
        DpcBillingID
        DpcDetails {
          id
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          __typename
        }
        epcCheckoutLink {
          checkoutID
          url
          expiry
          __typename
        }
        profile {
          email
          profilePhoto
          profileCompleted
          firstName
          lastName
          searchByName
          dob
          insuranceCarrier
          planID
          editStatus
          editRequested
          memberID
          streetAdress
          city
          state
          zip
          phone
          gender
          createdAt
          updatedAt
          owner
          __typename
        }
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        plan {
          id
          name
          planDetails
          createdAt
          updatedAt
          __typename
        }
        metadata
        createdAt
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDpcDetails = /* GraphQL */ `
  query GetDpcDetails($id: ID!) {
    getDpcDetails(id: $id) {
      id
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      plan {
        id
        name
        planDetails
        createdAt
        updatedAt
        __typename
      }
      metadata
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDpcDetails = /* GraphQL */ `
  query ListDpcDetails(
    $filter: ModelDpcDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDpcDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        plan {
          id
          name
          planDetails
          createdAt
          updatedAt
          __typename
        }
        metadata
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPlan = /* GraphQL */ `
  query GetPlan($id: ID!) {
    getPlan(id: $id) {
      id
      name
      planDetails
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPlans = /* GraphQL */ `
  query ListPlans(
    $filter: ModelPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        planDetails
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmployeeProfile = /* GraphQL */ `
  query GetEmployeeProfile($email: AWSEmail!) {
    getEmployeeProfile(email: $email) {
      email
      profilePhoto
      profileCompleted
      firstName
      lastName
      searchByName
      dob
      insuranceCarrier
      planID
      editStatus
      editRequested
      memberID
      firstVisit {
        reward
        procedure
        homePage
        firstVisit
        doctor
        surgery
        imaging
        infusion
        lab
        physicalTherapy
        __typename
      }
      streetAdress
      city
      state
      zip
      phone
      gender
      location {
        lat
        lon
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listEmployeeProfiles = /* GraphQL */ `
  query ListEmployeeProfiles(
    $email: AWSEmail
    $filter: ModelEmployeeProfileFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEmployeeProfiles(
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        email
        profilePhoto
        profileCompleted
        firstName
        lastName
        searchByName
        dob
        insuranceCarrier
        planID
        editStatus
        editRequested
        memberID
        firstVisit {
          reward
          procedure
          homePage
          firstVisit
          doctor
          surgery
          imaging
          infusion
          lab
          physicalTherapy
          __typename
        }
        streetAdress
        city
        state
        zip
        phone
        gender
        location {
          lat
          lon
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMember = /* GraphQL */ `
  query GetMember($id: ID!) {
    getMember(id: $id) {
      id
      firstName
      lastName
      fcmToken
      searchByName
      lastLogin
      relation
      group
      email
      emailType
      verified
      role
      userType
      inList
      emailID
      DpcBillingID
      DpcDetails {
        id
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        plan {
          id
          name
          planDetails
          createdAt
          updatedAt
          __typename
        }
        metadata
        createdAt
        updatedAt
        __typename
      }
      dpcEmployeeID
      dpcEmployee {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        inList
        lastLogin
        billing_status
        total_user
        members {
          nextToken
          __typename
        }
        dpcMembers {
          nextToken
          __typename
        }
        redeemedCoupon {
          promotionalID
          date
          __typename
        }
        promotionalID
        dpcDoctorID
        companyID {
          id
          approved
          name
          searchByName
          url
          phoneNumber
          email
          emailDomain
          planCategory
          profileID
          billing_status
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          companyRegiUnSubscribeIDId
          owner
          __typename
        }
        unSubscribeID {
          id
          reason
          createdAt
          updatedAt
          unSubscribeEmployeeId
          unSubscribeMemberId
          unSubscribeCompanyId
          owner
          __typename
        }
        DpcBillingID
        DpcDetails {
          id
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          __typename
        }
        epcCheckoutLink {
          checkoutID
          url
          expiry
          __typename
        }
        profile {
          email
          profilePhoto
          profileCompleted
          firstName
          lastName
          searchByName
          dob
          insuranceCarrier
          planID
          editStatus
          editRequested
          memberID
          streetAdress
          city
          state
          zip
          phone
          gender
          createdAt
          updatedAt
          owner
          __typename
        }
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        plan {
          id
          name
          planDetails
          createdAt
          updatedAt
          __typename
        }
        metadata
        createdAt
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      dpcDoctorID
      companyID {
        id
        approved
        name
        searchByName
        url
        phoneNumber
        email
        emailDomain
        planCategory
        customPlanDetails {
          price
          maxEmployess
          name
          __typename
        }
        profileID
        profile {
          email
          name
          url
          phoneNumber
          benefitInfoCall
          adminDetails
          companyID
          streetAddress
          zip
          city
          state
          benefitDocs
          visionDocs
          otherDocs
          createdAt
          updatedAt
          owner
          __typename
        }
        unSubscribeID {
          id
          reason
          createdAt
          updatedAt
          unSubscribeEmployeeId
          unSubscribeMemberId
          unSubscribeCompanyId
          owner
          __typename
        }
        employee {
          nextToken
          __typename
        }
        member {
          nextToken
          __typename
        }
        billing_status
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        plan {
          id
          name
          planDetails
          createdAt
          updatedAt
          __typename
        }
        metadata
        createdAt
        updatedAt
        companyRegiUnSubscribeIDId
        owner
        __typename
      }
      employeeID {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        inList
        lastLogin
        billing_status
        total_user
        members {
          nextToken
          __typename
        }
        dpcMembers {
          nextToken
          __typename
        }
        redeemedCoupon {
          promotionalID
          date
          __typename
        }
        promotionalID
        dpcDoctorID
        companyID {
          id
          approved
          name
          searchByName
          url
          phoneNumber
          email
          emailDomain
          planCategory
          profileID
          billing_status
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          companyRegiUnSubscribeIDId
          owner
          __typename
        }
        unSubscribeID {
          id
          reason
          createdAt
          updatedAt
          unSubscribeEmployeeId
          unSubscribeMemberId
          unSubscribeCompanyId
          owner
          __typename
        }
        DpcBillingID
        DpcDetails {
          id
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          __typename
        }
        epcCheckoutLink {
          checkoutID
          url
          expiry
          __typename
        }
        profile {
          email
          profilePhoto
          profileCompleted
          firstName
          lastName
          searchByName
          dob
          insuranceCarrier
          planID
          editStatus
          editRequested
          memberID
          streetAdress
          city
          state
          zip
          phone
          gender
          createdAt
          updatedAt
          owner
          __typename
        }
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        plan {
          id
          name
          planDetails
          createdAt
          updatedAt
          __typename
        }
        metadata
        createdAt
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      unSubscribeID {
        id
        reason
        employee {
          id
          firstName
          lastName
          fcmToken
          searchByName
          employeeEmail
          emailType
          verified
          role
          userType
          emailID
          inList
          lastLogin
          billing_status
          total_user
          promotionalID
          dpcDoctorID
          DpcBillingID
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          companyRegiEmployeeId
          employeeRegiUnSubscribeIDId
          owner
          __typename
        }
        member {
          id
          firstName
          lastName
          fcmToken
          searchByName
          lastLogin
          relation
          group
          email
          emailType
          verified
          role
          userType
          inList
          emailID
          DpcBillingID
          dpcEmployeeID
          dpcDoctorID
          billing_status
          accountStatus
          stripeSubscriptionID
          createdAt
          updatedAt
          employeeRegiMembersId
          companyRegiMemberId
          memberUnSubscribeIDId
          owner
          __typename
        }
        company {
          id
          approved
          name
          searchByName
          url
          phoneNumber
          email
          emailDomain
          planCategory
          profileID
          billing_status
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          companyRegiUnSubscribeIDId
          owner
          __typename
        }
        createdAt
        updatedAt
        unSubscribeEmployeeId
        unSubscribeMemberId
        unSubscribeCompanyId
        owner
        __typename
      }
      profile {
        email
        profilePhoto
        profileCompleted
        firstName
        lastName
        searchByName
        editStatus
        editRequested
        gender
        dob
        firstVisit {
          reward
          procedure
          homePage
          firstVisit
          doctor
          surgery
          imaging
          infusion
          lab
          physicalTherapy
          __typename
        }
        insuranceCarrier
        planID
        memberID
        streetAdress
        city
        state
        zip
        phone
        location {
          lat
          lon
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      billing_status
      accountStatus
      stripeSubscriptionID
      createdAt
      updatedAt
      employeeRegiMembersId
      companyRegiMemberId
      memberUnSubscribeIDId
      owner
      __typename
    }
  }
`;
export const listMembers = /* GraphQL */ `
  query ListMembers(
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        fcmToken
        searchByName
        lastLogin
        relation
        group
        email
        emailType
        verified
        role
        userType
        inList
        emailID
        DpcBillingID
        DpcDetails {
          id
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          __typename
        }
        dpcEmployeeID
        dpcEmployee {
          id
          firstName
          lastName
          fcmToken
          searchByName
          employeeEmail
          emailType
          verified
          role
          userType
          emailID
          inList
          lastLogin
          billing_status
          total_user
          promotionalID
          dpcDoctorID
          DpcBillingID
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          companyRegiEmployeeId
          employeeRegiUnSubscribeIDId
          owner
          __typename
        }
        dpcDoctorID
        companyID {
          id
          approved
          name
          searchByName
          url
          phoneNumber
          email
          emailDomain
          planCategory
          profileID
          billing_status
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          companyRegiUnSubscribeIDId
          owner
          __typename
        }
        employeeID {
          id
          firstName
          lastName
          fcmToken
          searchByName
          employeeEmail
          emailType
          verified
          role
          userType
          emailID
          inList
          lastLogin
          billing_status
          total_user
          promotionalID
          dpcDoctorID
          DpcBillingID
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          companyRegiEmployeeId
          employeeRegiUnSubscribeIDId
          owner
          __typename
        }
        unSubscribeID {
          id
          reason
          createdAt
          updatedAt
          unSubscribeEmployeeId
          unSubscribeMemberId
          unSubscribeCompanyId
          owner
          __typename
        }
        profile {
          email
          profilePhoto
          profileCompleted
          firstName
          lastName
          searchByName
          editStatus
          editRequested
          gender
          dob
          insuranceCarrier
          planID
          memberID
          streetAdress
          city
          state
          zip
          phone
          createdAt
          updatedAt
          owner
          __typename
        }
        billing_status
        accountStatus
        stripeSubscriptionID
        createdAt
        updatedAt
        employeeRegiMembersId
        companyRegiMemberId
        memberUnSubscribeIDId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMemberProfile = /* GraphQL */ `
  query GetMemberProfile($email: AWSEmail!) {
    getMemberProfile(email: $email) {
      email
      profilePhoto
      profileCompleted
      firstName
      lastName
      searchByName
      editStatus
      editRequested
      gender
      dob
      firstVisit {
        reward
        procedure
        homePage
        firstVisit
        doctor
        surgery
        imaging
        infusion
        lab
        physicalTherapy
        __typename
      }
      insuranceCarrier
      planID
      memberID
      streetAdress
      city
      state
      zip
      phone
      location {
        lat
        lon
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listMemberProfiles = /* GraphQL */ `
  query ListMemberProfiles(
    $email: AWSEmail
    $filter: ModelMemberProfileFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMemberProfiles(
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        email
        profilePhoto
        profileCompleted
        firstName
        lastName
        searchByName
        editStatus
        editRequested
        gender
        dob
        firstVisit {
          reward
          procedure
          homePage
          firstVisit
          doctor
          surgery
          imaging
          infusion
          lab
          physicalTherapy
          __typename
        }
        insuranceCarrier
        planID
        memberID
        streetAdress
        city
        state
        zip
        phone
        location {
          lat
          lon
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanyRegi = /* GraphQL */ `
  query GetCompanyRegi($id: ID!) {
    getCompanyRegi(id: $id) {
      id
      approved
      name
      searchByName
      url
      phoneNumber
      email
      emailDomain
      planCategory
      customPlanDetails {
        price
        maxEmployess
        name
        __typename
      }
      profileID
      profile {
        email
        name
        url
        phoneNumber
        benefitInfoCall
        adminDetails
        companyID
        company {
          id
          approved
          name
          searchByName
          url
          phoneNumber
          email
          emailDomain
          planCategory
          profileID
          billing_status
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          companyRegiUnSubscribeIDId
          owner
          __typename
        }
        streetAddress
        zip
        city
        state
        benefitDocs
        visionDocs
        otherDocs
        createdAt
        updatedAt
        owner
        __typename
      }
      unSubscribeID {
        id
        reason
        employee {
          id
          firstName
          lastName
          fcmToken
          searchByName
          employeeEmail
          emailType
          verified
          role
          userType
          emailID
          inList
          lastLogin
          billing_status
          total_user
          promotionalID
          dpcDoctorID
          DpcBillingID
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          companyRegiEmployeeId
          employeeRegiUnSubscribeIDId
          owner
          __typename
        }
        member {
          id
          firstName
          lastName
          fcmToken
          searchByName
          lastLogin
          relation
          group
          email
          emailType
          verified
          role
          userType
          inList
          emailID
          DpcBillingID
          dpcEmployeeID
          dpcDoctorID
          billing_status
          accountStatus
          stripeSubscriptionID
          createdAt
          updatedAt
          employeeRegiMembersId
          companyRegiMemberId
          memberUnSubscribeIDId
          owner
          __typename
        }
        company {
          id
          approved
          name
          searchByName
          url
          phoneNumber
          email
          emailDomain
          planCategory
          profileID
          billing_status
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          companyRegiUnSubscribeIDId
          owner
          __typename
        }
        createdAt
        updatedAt
        unSubscribeEmployeeId
        unSubscribeMemberId
        unSubscribeCompanyId
        owner
        __typename
      }
      employee {
        items {
          id
          firstName
          lastName
          fcmToken
          searchByName
          employeeEmail
          emailType
          verified
          role
          userType
          emailID
          inList
          lastLogin
          billing_status
          total_user
          promotionalID
          dpcDoctorID
          DpcBillingID
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          companyRegiEmployeeId
          employeeRegiUnSubscribeIDId
          owner
          __typename
        }
        nextToken
        __typename
      }
      member {
        items {
          id
          firstName
          lastName
          fcmToken
          searchByName
          lastLogin
          relation
          group
          email
          emailType
          verified
          role
          userType
          inList
          emailID
          DpcBillingID
          dpcEmployeeID
          dpcDoctorID
          billing_status
          accountStatus
          stripeSubscriptionID
          createdAt
          updatedAt
          employeeRegiMembersId
          companyRegiMemberId
          memberUnSubscribeIDId
          owner
          __typename
        }
        nextToken
        __typename
      }
      billing_status
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      plan {
        id
        name
        planDetails
        createdAt
        updatedAt
        __typename
      }
      metadata
      createdAt
      updatedAt
      companyRegiUnSubscribeIDId
      owner
      __typename
    }
  }
`;
export const listCompanyRegis = /* GraphQL */ `
  query ListCompanyRegis(
    $filter: ModelCompanyRegiFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyRegis(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        approved
        name
        searchByName
        url
        phoneNumber
        email
        emailDomain
        planCategory
        customPlanDetails {
          price
          maxEmployess
          name
          __typename
        }
        profileID
        profile {
          email
          name
          url
          phoneNumber
          benefitInfoCall
          adminDetails
          companyID
          streetAddress
          zip
          city
          state
          benefitDocs
          visionDocs
          otherDocs
          createdAt
          updatedAt
          owner
          __typename
        }
        unSubscribeID {
          id
          reason
          createdAt
          updatedAt
          unSubscribeEmployeeId
          unSubscribeMemberId
          unSubscribeCompanyId
          owner
          __typename
        }
        employee {
          nextToken
          __typename
        }
        member {
          nextToken
          __typename
        }
        billing_status
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        plan {
          id
          name
          planDetails
          createdAt
          updatedAt
          __typename
        }
        metadata
        createdAt
        updatedAt
        companyRegiUnSubscribeIDId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanyProfile = /* GraphQL */ `
  query GetCompanyProfile($email: AWSEmail!) {
    getCompanyProfile(email: $email) {
      email
      name
      url
      phoneNumber
      benefitInfoCall
      adminDetails
      companyID
      company {
        id
        approved
        name
        searchByName
        url
        phoneNumber
        email
        emailDomain
        planCategory
        customPlanDetails {
          price
          maxEmployess
          name
          __typename
        }
        profileID
        profile {
          email
          name
          url
          phoneNumber
          benefitInfoCall
          adminDetails
          companyID
          streetAddress
          zip
          city
          state
          benefitDocs
          visionDocs
          otherDocs
          createdAt
          updatedAt
          owner
          __typename
        }
        unSubscribeID {
          id
          reason
          createdAt
          updatedAt
          unSubscribeEmployeeId
          unSubscribeMemberId
          unSubscribeCompanyId
          owner
          __typename
        }
        employee {
          nextToken
          __typename
        }
        member {
          nextToken
          __typename
        }
        billing_status
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        plan {
          id
          name
          planDetails
          createdAt
          updatedAt
          __typename
        }
        metadata
        createdAt
        updatedAt
        companyRegiUnSubscribeIDId
        owner
        __typename
      }
      streetAddress
      zip
      city
      state
      benefitDocs
      visionDocs
      otherDocs
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCompanyProfiles = /* GraphQL */ `
  query ListCompanyProfiles(
    $email: AWSEmail
    $filter: ModelCompanyProfileFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCompanyProfiles(
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        email
        name
        url
        phoneNumber
        benefitInfoCall
        adminDetails
        companyID
        company {
          id
          approved
          name
          searchByName
          url
          phoneNumber
          email
          emailDomain
          planCategory
          profileID
          billing_status
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          companyRegiUnSubscribeIDId
          owner
          __typename
        }
        streetAddress
        zip
        city
        state
        benefitDocs
        visionDocs
        otherDocs
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUnSubscribe = /* GraphQL */ `
  query GetUnSubscribe($id: ID!) {
    getUnSubscribe(id: $id) {
      id
      reason
      employee {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        inList
        lastLogin
        billing_status
        total_user
        members {
          nextToken
          __typename
        }
        dpcMembers {
          nextToken
          __typename
        }
        redeemedCoupon {
          promotionalID
          date
          __typename
        }
        promotionalID
        dpcDoctorID
        companyID {
          id
          approved
          name
          searchByName
          url
          phoneNumber
          email
          emailDomain
          planCategory
          profileID
          billing_status
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          companyRegiUnSubscribeIDId
          owner
          __typename
        }
        unSubscribeID {
          id
          reason
          createdAt
          updatedAt
          unSubscribeEmployeeId
          unSubscribeMemberId
          unSubscribeCompanyId
          owner
          __typename
        }
        DpcBillingID
        DpcDetails {
          id
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          __typename
        }
        epcCheckoutLink {
          checkoutID
          url
          expiry
          __typename
        }
        profile {
          email
          profilePhoto
          profileCompleted
          firstName
          lastName
          searchByName
          dob
          insuranceCarrier
          planID
          editStatus
          editRequested
          memberID
          streetAdress
          city
          state
          zip
          phone
          gender
          createdAt
          updatedAt
          owner
          __typename
        }
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        plan {
          id
          name
          planDetails
          createdAt
          updatedAt
          __typename
        }
        metadata
        createdAt
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      member {
        id
        firstName
        lastName
        fcmToken
        searchByName
        lastLogin
        relation
        group
        email
        emailType
        verified
        role
        userType
        inList
        emailID
        DpcBillingID
        DpcDetails {
          id
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          __typename
        }
        dpcEmployeeID
        dpcEmployee {
          id
          firstName
          lastName
          fcmToken
          searchByName
          employeeEmail
          emailType
          verified
          role
          userType
          emailID
          inList
          lastLogin
          billing_status
          total_user
          promotionalID
          dpcDoctorID
          DpcBillingID
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          companyRegiEmployeeId
          employeeRegiUnSubscribeIDId
          owner
          __typename
        }
        dpcDoctorID
        companyID {
          id
          approved
          name
          searchByName
          url
          phoneNumber
          email
          emailDomain
          planCategory
          profileID
          billing_status
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          companyRegiUnSubscribeIDId
          owner
          __typename
        }
        employeeID {
          id
          firstName
          lastName
          fcmToken
          searchByName
          employeeEmail
          emailType
          verified
          role
          userType
          emailID
          inList
          lastLogin
          billing_status
          total_user
          promotionalID
          dpcDoctorID
          DpcBillingID
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          companyRegiEmployeeId
          employeeRegiUnSubscribeIDId
          owner
          __typename
        }
        unSubscribeID {
          id
          reason
          createdAt
          updatedAt
          unSubscribeEmployeeId
          unSubscribeMemberId
          unSubscribeCompanyId
          owner
          __typename
        }
        profile {
          email
          profilePhoto
          profileCompleted
          firstName
          lastName
          searchByName
          editStatus
          editRequested
          gender
          dob
          insuranceCarrier
          planID
          memberID
          streetAdress
          city
          state
          zip
          phone
          createdAt
          updatedAt
          owner
          __typename
        }
        billing_status
        accountStatus
        stripeSubscriptionID
        createdAt
        updatedAt
        employeeRegiMembersId
        companyRegiMemberId
        memberUnSubscribeIDId
        owner
        __typename
      }
      company {
        id
        approved
        name
        searchByName
        url
        phoneNumber
        email
        emailDomain
        planCategory
        customPlanDetails {
          price
          maxEmployess
          name
          __typename
        }
        profileID
        profile {
          email
          name
          url
          phoneNumber
          benefitInfoCall
          adminDetails
          companyID
          streetAddress
          zip
          city
          state
          benefitDocs
          visionDocs
          otherDocs
          createdAt
          updatedAt
          owner
          __typename
        }
        unSubscribeID {
          id
          reason
          createdAt
          updatedAt
          unSubscribeEmployeeId
          unSubscribeMemberId
          unSubscribeCompanyId
          owner
          __typename
        }
        employee {
          nextToken
          __typename
        }
        member {
          nextToken
          __typename
        }
        billing_status
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        plan {
          id
          name
          planDetails
          createdAt
          updatedAt
          __typename
        }
        metadata
        createdAt
        updatedAt
        companyRegiUnSubscribeIDId
        owner
        __typename
      }
      createdAt
      updatedAt
      unSubscribeEmployeeId
      unSubscribeMemberId
      unSubscribeCompanyId
      owner
      __typename
    }
  }
`;
export const listUnSubscribes = /* GraphQL */ `
  query ListUnSubscribes(
    $filter: ModelUnSubscribeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUnSubscribes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        reason
        employee {
          id
          firstName
          lastName
          fcmToken
          searchByName
          employeeEmail
          emailType
          verified
          role
          userType
          emailID
          inList
          lastLogin
          billing_status
          total_user
          promotionalID
          dpcDoctorID
          DpcBillingID
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          companyRegiEmployeeId
          employeeRegiUnSubscribeIDId
          owner
          __typename
        }
        member {
          id
          firstName
          lastName
          fcmToken
          searchByName
          lastLogin
          relation
          group
          email
          emailType
          verified
          role
          userType
          inList
          emailID
          DpcBillingID
          dpcEmployeeID
          dpcDoctorID
          billing_status
          accountStatus
          stripeSubscriptionID
          createdAt
          updatedAt
          employeeRegiMembersId
          companyRegiMemberId
          memberUnSubscribeIDId
          owner
          __typename
        }
        company {
          id
          approved
          name
          searchByName
          url
          phoneNumber
          email
          emailDomain
          planCategory
          profileID
          billing_status
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          companyRegiUnSubscribeIDId
          owner
          __typename
        }
        createdAt
        updatedAt
        unSubscribeEmployeeId
        unSubscribeMemberId
        unSubscribeCompanyId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProcedures = /* GraphQL */ `
  query GetProcedures($id: ID!) {
    getProcedures(id: $id) {
      id
      name
      searchByName
      savings
      bazarPrice
      marketPrice
      reward
      bodyPart
      focusOnBody
      about
      description
      faq
      belongsToService
      image
      activeOnPortal
      byName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProcedures = /* GraphQL */ `
  query ListProcedures(
    $filter: ModelProceduresFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProcedures(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        searchByName
        savings
        bazarPrice
        marketPrice
        reward
        bodyPart
        focusOnBody
        about
        description
        faq
        belongsToService
        image
        activeOnPortal
        byName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const procedureByName = /* GraphQL */ `
  query ProcedureByName(
    $searchByName: String!
    $sortDirection: ModelSortDirection
    $filter: ModelproceduresFilterInput
    $limit: Int
    $nextToken: String
  ) {
    procedureByName(
      searchByName: $searchByName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        searchByName
        savings
        bazarPrice
        marketPrice
        reward
        bodyPart
        focusOnBody
        about
        description
        faq
        belongsToService
        image
        activeOnPortal
        byName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const proceduresByName = /* GraphQL */ `
  query ProceduresByName(
    $byName: String!
    $searchByName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelproceduresFilterInput
    $limit: Int
    $nextToken: String
  ) {
    proceduresByName(
      byName: $byName
      searchByName: $searchByName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        searchByName
        savings
        bazarPrice
        marketPrice
        reward
        bodyPart
        focusOnBody
        about
        description
        faq
        belongsToService
        image
        activeOnPortal
        byName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchProcedures = /* GraphQL */ `
  query SearchProcedures(
    $filter: SearchableproceduresFilterInput
    $sort: [SearchableproceduresSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableproceduresAggregationInput]
  ) {
    searchProcedures(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        searchByName
        savings
        bazarPrice
        marketPrice
        reward
        bodyPart
        focusOnBody
        about
        description
        faq
        belongsToService
        image
        activeOnPortal
        byName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getCsvFileData = /* GraphQL */ `
  query GetCsvFileData($id: ID!) {
    getCsvFileData(id: $id) {
      payer
      produt
      fileyear
      fileMonth
      groupCode
      cvgLevel
      memberSSN
      memberID
      memberSfx
      memberIDSeq
      memberRelCode
      memberGender
      memberDOB
      memberAddress1
      memberAddress2
      memberCity
      memberState
      memberZip
      memberCountry
      memberMedCvg
      subscrLastName
      subscrFirstName
      subscrMI
      memberLastName
      memberFirstName
      memberMI
      medEffDt
      medTermDt
      medStatus
      deptCode
      medBenPlanCode
      rxBenPlanCode
      cobFlag
      pcpTaxID
      pcpFirstName
      pcpLastName
      pcpAddressLine1
      pcpAddressLine2
      pcpCity
      pcpState
      pcpZipCode
      medicareCovSel
      lasered
      laseredAmount
      GroupName
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCsvFileData = /* GraphQL */ `
  query ListCsvFileData(
    $filter: ModelCsvFileDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCsvFileData(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        payer
        produt
        fileyear
        fileMonth
        groupCode
        cvgLevel
        memberSSN
        memberID
        memberSfx
        memberIDSeq
        memberRelCode
        memberGender
        memberDOB
        memberAddress1
        memberAddress2
        memberCity
        memberState
        memberZip
        memberCountry
        memberMedCvg
        subscrLastName
        subscrFirstName
        subscrMI
        memberLastName
        memberFirstName
        memberMI
        medEffDt
        medTermDt
        medStatus
        deptCode
        medBenPlanCode
        rxBenPlanCode
        cobFlag
        pcpTaxID
        pcpFirstName
        pcpLastName
        pcpAddressLine1
        pcpAddressLine2
        pcpCity
        pcpState
        pcpZipCode
        medicareCovSel
        lasered
        laseredAmount
        GroupName
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSelfInsuredEmp = /* GraphQL */ `
  query GetSelfInsuredEmp($email: AWSEmail!) {
    getSelfInsuredEmp(email: $email) {
      email
      firstName
      lastName
      companyName
      companyID
      registered
      searchByName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listSelfInsuredEmps = /* GraphQL */ `
  query ListSelfInsuredEmps(
    $email: AWSEmail
    $filter: ModelSelfInsuredEmpFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSelfInsuredEmps(
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        email
        firstName
        lastName
        companyName
        companyID
        registered
        searchByName
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPromotionalPartner = /* GraphQL */ `
  query GetPromotionalPartner($id: ID!) {
    getPromotionalPartner(id: $id) {
      id
      name
      email
      phone
      url
      searchByName
      streetAddress
      city
      state
      zip
      promotionalCode
      referal {
        discountType
        discount
        planType
        couponID
        promotionalID
        promotionalCode
        active
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPromotionalPartners = /* GraphQL */ `
  query ListPromotionalPartners(
    $filter: ModelPromotionalPartnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPromotionalPartners(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        phone
        url
        searchByName
        streetAddress
        city
        state
        zip
        promotionalCode
        referal {
          discountType
          discount
          planType
          couponID
          promotionalID
          promotionalCode
          active
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEligibleMembers = /* GraphQL */ `
  query GetEligibleMembers($id: ID!) {
    getEligibleMembers(id: $id) {
      payer
      lastSend
      product
      fileYear
      fileMonth
      groupCode
      cvgLevel
      memberSSN
      memberID
      memberSfx
      memberIDSeq
      memberRelCode
      memberGender
      memberDOB
      memberAddress1
      memberAddress2
      memberCity
      memberState
      memberZip
      memberCounty
      memberMedCvg
      subscrLastName
      subscrFirstName
      subscrMI
      memberLastName
      memberFirstName
      memberMI
      medEffDt
      medTermDt
      medStatus
      deptCode
      medBenPlanCode
      rxBenPlanCode
      cobFlag
      pcpTaxID
      pcpFirstName
      pcpLastName
      pcpAddressLine1
      pcpAddressLine2
      pcpCity
      pcpState
      pcpZipCode
      medicareCovSel
      lasered
      laseredAmount
      GroupName
      registered
      invited
      companyID
      regiID
      email
      phone
      searchByName
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEligibleMembers = /* GraphQL */ `
  query ListEligibleMembers(
    $filter: ModelEligibleMembersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEligibleMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        payer
        lastSend
        product
        fileYear
        fileMonth
        groupCode
        cvgLevel
        memberSSN
        memberID
        memberSfx
        memberIDSeq
        memberRelCode
        memberGender
        memberDOB
        memberAddress1
        memberAddress2
        memberCity
        memberState
        memberZip
        memberCounty
        memberMedCvg
        subscrLastName
        subscrFirstName
        subscrMI
        memberLastName
        memberFirstName
        memberMI
        medEffDt
        medTermDt
        medStatus
        deptCode
        medBenPlanCode
        rxBenPlanCode
        cobFlag
        pcpTaxID
        pcpFirstName
        pcpLastName
        pcpAddressLine1
        pcpAddressLine2
        pcpCity
        pcpState
        pcpZipCode
        medicareCovSel
        lasered
        laseredAmount
        GroupName
        registered
        invited
        companyID
        regiID
        email
        phone
        searchByName
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byCompanyID = /* GraphQL */ `
  query ByCompanyID(
    $companyID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModeleligibleMembersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byCompanyID(
      companyID: $companyID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        payer
        lastSend
        product
        fileYear
        fileMonth
        groupCode
        cvgLevel
        memberSSN
        memberID
        memberSfx
        memberIDSeq
        memberRelCode
        memberGender
        memberDOB
        memberAddress1
        memberAddress2
        memberCity
        memberState
        memberZip
        memberCounty
        memberMedCvg
        subscrLastName
        subscrFirstName
        subscrMI
        memberLastName
        memberFirstName
        memberMI
        medEffDt
        medTermDt
        medStatus
        deptCode
        medBenPlanCode
        rxBenPlanCode
        cobFlag
        pcpTaxID
        pcpFirstName
        pcpLastName
        pcpAddressLine1
        pcpAddressLine2
        pcpCity
        pcpState
        pcpZipCode
        medicareCovSel
        lasered
        laseredAmount
        GroupName
        registered
        invited
        companyID
        regiID
        email
        phone
        searchByName
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSelfServingUsers = /* GraphQL */ `
  query GetSelfServingUsers($id: ID!) {
    getSelfServingUsers(id: $id) {
      id
      firstName
      lastName
      dob
      email
      phoneNumber
      streetAddress
      city
      state
      zipCode
      insuranceCarrier
      reference
      question
      doctorID
      startDate
      isDependent
      uuid
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSelfServingUsers = /* GraphQL */ `
  query ListSelfServingUsers(
    $filter: ModelSelfServingUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSelfServingUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        dob
        email
        phoneNumber
        streetAddress
        city
        state
        zipCode
        insuranceCarrier
        reference
        question
        doctorID
        startDate
        isDependent
        uuid
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      content
      senderID
      systemContent
      senderDetails {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        inList
        lastLogin
        billing_status
        total_user
        members {
          nextToken
          __typename
        }
        dpcMembers {
          nextToken
          __typename
        }
        redeemedCoupon {
          promotionalID
          date
          __typename
        }
        promotionalID
        dpcDoctorID
        companyID {
          id
          approved
          name
          searchByName
          url
          phoneNumber
          email
          emailDomain
          planCategory
          profileID
          billing_status
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          companyRegiUnSubscribeIDId
          owner
          __typename
        }
        unSubscribeID {
          id
          reason
          createdAt
          updatedAt
          unSubscribeEmployeeId
          unSubscribeMemberId
          unSubscribeCompanyId
          owner
          __typename
        }
        DpcBillingID
        DpcDetails {
          id
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          __typename
        }
        epcCheckoutLink {
          checkoutID
          url
          expiry
          __typename
        }
        profile {
          email
          profilePhoto
          profileCompleted
          firstName
          lastName
          searchByName
          dob
          insuranceCarrier
          planID
          editStatus
          editRequested
          memberID
          streetAdress
          city
          state
          zip
          phone
          gender
          createdAt
          updatedAt
          owner
          __typename
        }
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        plan {
          id
          name
          planDetails
          createdAt
          updatedAt
          __typename
        }
        metadata
        createdAt
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      chatId
      chat {
        id
        senderID
        senderDetails {
          id
          firstName
          lastName
          fcmToken
          searchByName
          employeeEmail
          emailType
          verified
          role
          userType
          emailID
          inList
          lastLogin
          billing_status
          total_user
          promotionalID
          dpcDoctorID
          DpcBillingID
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          companyRegiEmployeeId
          employeeRegiUnSubscribeIDId
          owner
          __typename
        }
        admin
        adminDetails {
          id
          firstName
          lastName
          fcmToken
          searchByName
          employeeEmail
          emailType
          verified
          role
          userType
          emailID
          inList
          lastLogin
          billing_status
          total_user
          promotionalID
          dpcDoctorID
          DpcBillingID
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          companyRegiEmployeeId
          employeeRegiUnSubscribeIDId
          owner
          __typename
        }
        messages {
          nextToken
          __typename
        }
        status
        createdAt
        feedback
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        senderID
        systemContent
        senderDetails {
          id
          firstName
          lastName
          fcmToken
          searchByName
          employeeEmail
          emailType
          verified
          role
          userType
          emailID
          inList
          lastLogin
          billing_status
          total_user
          promotionalID
          dpcDoctorID
          DpcBillingID
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          companyRegiEmployeeId
          employeeRegiUnSubscribeIDId
          owner
          __typename
        }
        chatId
        chat {
          id
          senderID
          admin
          status
          createdAt
          feedback
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChat = /* GraphQL */ `
  query GetChat($id: ID!) {
    getChat(id: $id) {
      id
      senderID
      senderDetails {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        inList
        lastLogin
        billing_status
        total_user
        members {
          nextToken
          __typename
        }
        dpcMembers {
          nextToken
          __typename
        }
        redeemedCoupon {
          promotionalID
          date
          __typename
        }
        promotionalID
        dpcDoctorID
        companyID {
          id
          approved
          name
          searchByName
          url
          phoneNumber
          email
          emailDomain
          planCategory
          profileID
          billing_status
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          companyRegiUnSubscribeIDId
          owner
          __typename
        }
        unSubscribeID {
          id
          reason
          createdAt
          updatedAt
          unSubscribeEmployeeId
          unSubscribeMemberId
          unSubscribeCompanyId
          owner
          __typename
        }
        DpcBillingID
        DpcDetails {
          id
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          __typename
        }
        epcCheckoutLink {
          checkoutID
          url
          expiry
          __typename
        }
        profile {
          email
          profilePhoto
          profileCompleted
          firstName
          lastName
          searchByName
          dob
          insuranceCarrier
          planID
          editStatus
          editRequested
          memberID
          streetAdress
          city
          state
          zip
          phone
          gender
          createdAt
          updatedAt
          owner
          __typename
        }
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        plan {
          id
          name
          planDetails
          createdAt
          updatedAt
          __typename
        }
        metadata
        createdAt
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      admin
      adminDetails {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        inList
        lastLogin
        billing_status
        total_user
        members {
          nextToken
          __typename
        }
        dpcMembers {
          nextToken
          __typename
        }
        redeemedCoupon {
          promotionalID
          date
          __typename
        }
        promotionalID
        dpcDoctorID
        companyID {
          id
          approved
          name
          searchByName
          url
          phoneNumber
          email
          emailDomain
          planCategory
          profileID
          billing_status
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          companyRegiUnSubscribeIDId
          owner
          __typename
        }
        unSubscribeID {
          id
          reason
          createdAt
          updatedAt
          unSubscribeEmployeeId
          unSubscribeMemberId
          unSubscribeCompanyId
          owner
          __typename
        }
        DpcBillingID
        DpcDetails {
          id
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          __typename
        }
        epcCheckoutLink {
          checkoutID
          url
          expiry
          __typename
        }
        profile {
          email
          profilePhoto
          profileCompleted
          firstName
          lastName
          searchByName
          dob
          insuranceCarrier
          planID
          editStatus
          editRequested
          memberID
          streetAdress
          city
          state
          zip
          phone
          gender
          createdAt
          updatedAt
          owner
          __typename
        }
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        plan {
          id
          name
          planDetails
          createdAt
          updatedAt
          __typename
        }
        metadata
        createdAt
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      messages {
        items {
          id
          content
          senderID
          systemContent
          chatId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      status
      createdAt
      feedback
      updatedAt
      __typename
    }
  }
`;
export const listChats = /* GraphQL */ `
  query ListChats(
    $filter: ModelChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        senderID
        senderDetails {
          id
          firstName
          lastName
          fcmToken
          searchByName
          employeeEmail
          emailType
          verified
          role
          userType
          emailID
          inList
          lastLogin
          billing_status
          total_user
          promotionalID
          dpcDoctorID
          DpcBillingID
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          companyRegiEmployeeId
          employeeRegiUnSubscribeIDId
          owner
          __typename
        }
        admin
        adminDetails {
          id
          firstName
          lastName
          fcmToken
          searchByName
          employeeEmail
          emailType
          verified
          role
          userType
          emailID
          inList
          lastLogin
          billing_status
          total_user
          promotionalID
          dpcDoctorID
          DpcBillingID
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          companyRegiEmployeeId
          employeeRegiUnSubscribeIDId
          owner
          __typename
        }
        messages {
          nextToken
          __typename
        }
        status
        createdAt
        feedback
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const senderChatByDate = /* GraphQL */ `
  query SenderChatByDate(
    $senderID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    senderChatByDate(
      senderID: $senderID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        senderID
        senderDetails {
          id
          firstName
          lastName
          fcmToken
          searchByName
          employeeEmail
          emailType
          verified
          role
          userType
          emailID
          inList
          lastLogin
          billing_status
          total_user
          promotionalID
          dpcDoctorID
          DpcBillingID
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          companyRegiEmployeeId
          employeeRegiUnSubscribeIDId
          owner
          __typename
        }
        admin
        adminDetails {
          id
          firstName
          lastName
          fcmToken
          searchByName
          employeeEmail
          emailType
          verified
          role
          userType
          emailID
          inList
          lastLogin
          billing_status
          total_user
          promotionalID
          dpcDoctorID
          DpcBillingID
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          companyRegiEmployeeId
          employeeRegiUnSubscribeIDId
          owner
          __typename
        }
        messages {
          nextToken
          __typename
        }
        status
        createdAt
        feedback
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
