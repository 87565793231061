import "./typography.less";
import "./App.less";
import "./responsive.less";
import Routes from "./Routers/Routes";
import Amplify from "aws-amplify";
import config from "./aws-exports";
import ReactGA from 'react-ga';

ReactGA.initialize('G-7YZJN0K8V0');

const updatedAwsConfig = {
  ...config,
  // Set API to use id token. This will ensure email is passed within the token
  // API: {
  //   graphql_headers: async () => ({
  //     Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
  //   }),
  // },
};

Amplify.configure(updatedAwsConfig);

function App() {
  if (process.env.NODE_ENV !== "development") {
    console.log = () => {};
    console.warn = () => {};
    console.error = () => {};
  }
  return (
    <div className="App">
      <Routes />
    </div>
  );
}

export default App;
